import React, { useEffect, useState, useRef} from 'react'
import {NavLink } from 'react-router-dom';
import { useStateValue } from "../StateProvider";
import {analytics, auth} from "../../config/firebase-config";
import { ReactComponent as LogoSvg2 } from '../../SVGs/Logo2.svg';
import './Header.css';
import {  signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; 
import DropdownList from '../Dropdown/Dropdown';
import emptyHeaderImage from '../ProfileCard/Default_pfp.jpg'; 
import { logEvent } from 'firebase/analytics';
import useOutsideClick from '../useOutsideClick';
import { Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';

function Header(props) {
  const location = useLocation();
  const [{basket},{user}] = useStateValue();
  const basketName = basket && basket.length > 0 ? props.name + '.' + props.surname : '';
  const basketNameNav = basketName.toLowerCase();
  const basketNameHeader = basketName.replace('.', ' ');
  const navigate = useNavigate();
  const ref = useRef(null);

 
  
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);

  useOutsideClick(ref, () => {
    if (settingsDropdownOpen) setSettingsDropdownOpen(false);
  });
  


   

  async function showList(event) {
    if (document.querySelector('.selected')) {
      let list = document.querySelector('.selected');
      list.classList.remove('selected');
    }
      event.target.classList.add('selected');     
}

function logOut() {
  
    signOut(auth).then(() => {
      navigate('/login');  // after sign out, redirect to login
    }).catch((error) => {
      console.error("Sign-out error:", error);
    });
  }

  const handleClick = () => { 
    if (auth.currentUser) {
      logEvent(analytics, 'log_out',{
      })
      logOut();
    } else {
      logEvent(analytics, 'go_to_login',{
      })
      logOut();
      navigate('/login');
    }
  };

  const handleRazgovorClick = (e) => {
    props.setType('razgovor');
    showList(e);
    logEvent(analytics,'terapist_list_change',{
      list_category:'razgovor',
      
    }) }

  const handleSavetnikClick = (e) => {
    props.setType('savetnik');
    showList(e);
    logEvent(analytics,'terapist_list_change',{
      list_category:'savetnik',
      
    }) }

  const handlePsychotherapistClick = (e) => {
    props.setType('psychotherapy');
    showList(e);
    logEvent(analytics,'terapist_list_change',{
      list_category:'psychotherapy',
    })
  }
  
  const handleLogoClick = () =>
  {
    navigate('/');
    logEvent(analytics, 'go_to_feed', {
      component: "header"
    })
  }

  /* () => { logEvent(analytics, 'go_to_profile', { user_type: basket[0].userType }) } to = {`/profile/${basketNameNav}`
} */
  return (
    <header>
      <nav className='Header' aria-label="Main Navigation">
        <LogoSvg2 className='Logo2' onClick={handleLogoClick} aria-label="Home" tabIndex={0} role="button"></LogoSvg2>
       {/*  <div className='Lists my-second-step'>
          <button className='List List1' onClick={handleRazgovorClick} aria-label="Razgovori">
            {location.pathname.length > 1 ? "" : 'Razgovori'}
          </button>
          <button className='List' onClick={handleSavetnikClick} aria-label="EVA Savetnici">
            {location.pathname.length > 1 ? "" : <p>EVA <br /> Savetnici</p>}
          </button>
          <button className='List selected' onClick={handlePsychotherapistClick} aria-label="Terapeuti i Savetnici">
            {location.pathname.length > 1 ? "" : <p>Terapeuti i <br />Savetnici</p>}
          </button>
        </div> */}
        <span className='step2'>
          {  location.pathname.length === 1 ? <DropdownList className="my-second-step" currentType={props.currentType} setType={props.setType} location={location} />
: null}        </span>
        <div ref={ref} onClick={() => { setSettingsDropdownOpen(prev => !prev); settingsDropdownOpen ? logEvent(analytics, "close_header_dropdown") : logEvent(analytics, "open_header_dropdown")}} className='Profile' role="button" aria-haspopup="true" aria-expanded={settingsDropdownOpen}>
          <>
            {<img src={basket[0]?.imageURL || emptyHeaderImage} className='ProfileImage2' alt="Profile" />}
            {settingsDropdownOpen && (
              <div className='settingsDropdown' role="menu">
                <ul>
                  {auth.currentUser && (
                    <li className='settingsDropdownLi'>
                      <NavLink
                        to={`/profile/${basketNameNav}`}
                        className='nav-link'
                        activeClassName='nav-link-active'
                        aria-label="Profile"
                        onClick={logEvent(analytics, "go_to_profile", {
                          component:'header'
                        })}
                      >
                        Profilna
                      </NavLink>
                    </li>
                  )}
                  <li className='settingsDropdownLi'>
                    <NavLink
                      to='/'
                      className='nav-link'
                      activeClassName='nav-link-active'
                      aria-label="Home"
                      onClick={logEvent(analytics, "go_to_feed", {
                        component: 'header'
                      })}
                    >
                      Početna
                    </NavLink>
                  </li>
                  {auth.currentUser && (
                    <li className='settingsDropdownLi'>
                      <NavLink
                        to={`/profile/${basketNameNav}/appointments`}
                        className='nav-link'
                        activeClassName='nav-link-active'
                        aria-label="Appointments"
                        onClick={logEvent(analytics, "go_to_appointments", {
                          component: 'header'
                        })}
                      >
                        Termini
                      </NavLink>
                    </li>
                  )}
                  {auth.currentUser && (
                    <li className='settingsDropdownLi'>
                      <NavLink
                        to={`/message/${basketNameNav}`}
                        className='nav-link'
                        activeClassName='nav-link-active'
                        aria-label="Messages"
                        onClick={logEvent(analytics, "go_to_messages", {
                          component: 'header'
                        })}
                      >
                        Poruke
                      </NavLink>
                    </li>
                  )}
                  <li className='settingsDropdownLi'>
                    <NavLink
                      to="/privacy-policy"
                      className='nav-link'
                      activeClassName='nav-link-active'
                      onClick={logEvent(analytics, "go_to_privacy_policy", {
                        component: 'header'
                      })}
                      
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li className='settingsDropdownLi'>
                    <NavLink
                      to="/terms-and-conditions"
                      className='nav-link'
                      activeClassName='nav-link-active'
                      aria-label="Terms and Conditions"
                      onClick={logEvent(analytics, "go_to_terms_and_condition", {
                        component: 'header'
                      })}
                    >
                      Terms and Conditions
                    </NavLink>
                  </li>
                </ul>

              </div>
            )}
            {auth.currentUser ? <p className='HeaderName'>{basketNameHeader}</p> : <div className='drop-down-button'><FaChevronDown /></div>}
          </>
        </div>
        <button className='LogOut' onClick={handleClick} aria-label={auth.currentUser ? 'Izloguj se' : 'Registruj se'}>
          {auth.currentUser ? 'Izloguj se' : 'Registruj se'}
        </button>
      </nav>
    </header>
  );
};

export default Header;