import React, { useEffect } from 'react'
import { useState} from "react";
import { auth, db, app, analytics } from "../../config/firebase-config";
import {signOut,signInWithEmailAndPassword,createUserWithEmailAndPassword} from "firebase/auth";
import {  doc, setDoc,getDoc,arrayUnion,updateDoc } from "firebase/firestore"; 
import { useStateValue } from '../StateProvider';
import './LoginPage.css';
import { ReactComponent as TherapistSvg } from '../../SVGs/Therapist.svg';
import { ReactComponent as ClientSvg } from '../../SVGs/Client.svg';
import ClientSignUp from '../ClientSignUp/clientSignUp';
import PsyhotherapystSingUp from '../PsychotherapystSingUp/PsychotherapystSingUp';
import { ReactComponent as LogoSvg } from '../../SVGs/Logo.svg';
import { useNavigate } from 'react-router-dom';
import showPasswordImage from './show.png';
import hidePasswordImage from './hide (1).png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InstructionsDialog from '../PopUps/InstructionsDialog';
import SpeechBubble from "../SpeechBubble/SpeechBubble";
import { ReactComponent as Avatar } from '../../SVGs/Avatar.svg'; 
import Joyride, { CallBackProps, STATUS, ACTIONS } from 'react-joyride';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions'; 
import RingLoader from "react-spinners/RingLoader"; 
import { set } from 'date-fns';



  const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  /* const [phone, setPhone] = useState(""); */
  const [certificate, setCertificate] = useState("");
  const [adress, setAdress] = useState("");
  const [{basket},dispatch,] = useStateValue();
  const [client, setClient] = useState(true);
  const [signInP, setSignInP] = useState(false);
  const [userType, setUserType] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [run, setRun] = useState(false);
    const [avatarSeenThisSesion, setAvatarSeenThisSesion] = useState(false);
    
    const [privacyPoliceAccepted, setPrivacyPoliceAccepted] = useState(false);
    const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState(false);
    const [signInLoading, setSignInLoading] = useState(false);


    useEffect(() => {
      if (!basket[0]?.avatarSeenRegistration) {
        setRun(true);
        dispatch({
          type: 'UPDATE_BASKET_PROPERTY',
          property: 'avatarSeenRegistration', // The name of the property you want to update
          value: true // The new value for the property
        });
      }
    },[])
  
    const functions = getFunctions(app);
    const sendEmail = httpsCallable(functions, 'sendEmail');

  const [steps, setSteps] = useState([
    {
      target: ".inputsDiv" ,
      placement: "center",
      disableBeacon:true,
      content: 
      <div className="AvatarContainer" style={{position:"relative", top:"3rem"}}>
        <Avatar/>
        <SpeechBubble style={{position:"relative", bottom:"5rem", left:"-1rem"}}>
          <p>
            Ukoliko se registruješ kao klijent, odmah nakon registracije moći ćeš da se uloguješ na svoj profil, a ukoliko se registruješ kao ponuđač, uslove za registraciju možeš pogledati klikom na info znak pored „Registruj se".
          </p>
        </SpeechBubble>
      </div>,
      
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 80vw,90vh)",
              zIndex: 100,
              offset: 100
            },
           
            buttonBack: {
              color: 'black', // This will apply the color black to the text of the back button
            },
            
          }
    }
  ])
  

  // Function to open the dialog
  const openDialog = () => {
    logEvent(analytics,'open_registration_info',{
    })
    setIsDialogOpen(true);
  }

  // Function to close the dialog
    const closeDialog = () => {
      setIsDialogOpen(false)
      logEvent(analytics,'close_registration_info')
  };
  
  function validateEmail(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }
  
  function validatePassword(password) {
    // Regular expression to check for minimum length of 8 characters
    var lengthCheck = /^.{8,}$/;
    // Regular expression to disallow characters that could be used in HTML or JavaScript injections
    var injectionCheck = /[<>\/'";:]/;

    // First, test if the password meets the length requirement
    var isValidLength = lengthCheck.test(password);
    // Then, check if the password contains any characters that are not allowed
    var isInjectionSafe = !injectionCheck.test(password);

    // The password is valid if it's long enough and doesn't contain dangerous characters
    return isValidLength && isInjectionSafe;
}  

  function validateName(name) {
    // Includes Latin, Cyrillic characters, and specific special characters like Ć, ć, š, etc.
    const re = /^[A-Za-z\u0400-\u04FF\s-ćĆčČđĐšŠžŽ]+$/;
    return re.test(name.trim()) && name.trim().length > 0;
}

function validateSurname(surname) {
    // Includes Latin, Cyrillic characters, and specific special characters like Ć, ć, š, etc.
    const re = /^[A-Za-z\u0400-\u04FF\s-ćĆčČđĐšŠžŽ]+$/;
    return re.test(surname.trim()) && surname.trim().length > 0;
}


  
  /* function validatePhone(phone) {
    const re = /^[0-9]+$/;
    return re.test(phone) && phone.trim().length > 0;
  } */

  const backToSingIn = () => {
    setSignInP(true);
    logEvent(analytics,'go_from_singup_to_login',{type_selected:userType})
  }
  

  const signUpPsyhotherapyst = async () => {
    console.log('signUpPsychotherapist is executed');
    setSignInLoading(true);

    if (!validateEmail(email)) {
      alert('Morate uneti validan email');
      setSignInLoading(false)
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'email'
      })
      return;
    }
  
    if (!validatePassword(password)) {
      alert("Lozinka mora sadržati najmanje 8 karaktera. Nije dozvoljeno koristiti simbole poput '<', '>', ili '&'.");
      setSignInLoading(false)
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'password'
      })
      return;
    } 
    if (!validateName(name)) {
      alert('Ime mora sadržati samo slova');
      setSignInLoading(false)
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'name'
      })
        return;
      }
    if (!validateSurname(surname)) {
      alert('Prezime mora sadržati samo slova');
      setSignInLoading(false)
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'surname'
      })
        return;
    }

    if (!privacyPoliceAccepted) {
      alert('Potrebno je da prihvatite politiku privatnosti kako biste se registrovali');
      setSignInLoading(false)
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'privacy_police'
      })
        return;
    }
    if (!termsAndConditionAccepted) {
      alert('Potrebno je da prihvatite uslove korišćenja kako biste se registrovali');
      setSignInLoading(false)
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'terms_and_conditions'
      })
        return;
    }
    

      
    /* if (!validatePhone(phone)) {
        alert('Broj telefona mora sadržati samo brojeve');
        return;
      } */
    
    

    try {
      let listType = null;
      await createUserWithEmailAndPassword(auth, email, password);
            await setDoc(doc(db, 'Clients', auth.currentUser.uid), {
            email: email.trim(),
            name: name.trim(),
            surname: surname.trim(),
            userType: userType.trim(),
            averageRating: NaN,
            privacyPoliceAccepted: privacyPoliceAccepted,
            termsAndConditionAccepted: termsAndConditionAccepted,
            analytics_user: true
            });
            const consentRef = doc(db, 'Clients', auth.currentUser.uid, 'consents', new Date().toISOString());
            await setDoc(consentRef, {
              privacyPoliceAccepted: privacyPoliceAccepted,
              termsAndConditionAccepted: termsAndConditionAccepted
          });
          switch(userType) {
            case 'razgovor':
              listType = 'ListOfIds1';
              break;
            case 'savetnik':
              listType = 'ListOfIds2';
              break;
            case 'psychotherapy':
              listType = 'ListOfIds3';
              break;
          }    
  
          dispatch({
            type: "ADD_TO_BASKET",
            item: {
              email: email.trim(),
              name: name.trim(),
              surname: surname.trim(),
              userType: userType.trim(),
              privacyPoliceAccepted: privacyPoliceAccepted,
              termsAndConditionAccepted:termsAndConditionAccepted
            }
          });
      setUserId(analytics, auth.currentUser.uid);
      setUserProperties(analytics, {
        user_type: userType.trim()
      })
          logEvent(analytics,"sign_up",{
          })
      
      let documentsRequired = null;
      switch (userType) {
        case 'razgovor':
          documentsRequired = `
      <b>1.kratku biografiju ili CV.</b>
      <br><br>
    `;
          break;

        case 'psychotherapy':
          documentsRequired = `
      <b>1. kratku biografiju ili CV,</b> 
      <br><br>
      <b>2. sliku sertifikata,</b>
      <br><br> 
      <b>3. sliku diplome ili indeksa.</b>
      <br><br>
    `;
          break;

        case 'savetnik':
          documentsRequired = `
      <b>1. kratku biografiju ili CV,</b> 
      <br><br>
      <b>2. sliku relevatnih sertifikata i/ili indeksa/diplome.</b>
      <br><br>
    `;
          break;
      }
      
    
      
      const emailData = {
        to: email.trim(), // Replace with the recipient's email address
        subject: 'Prijava na EVA aplikaciji',
        messageBody: `
  <b>Hvala na prijavi!</b> 
  <br><br>
  Da bismo odobrili Vaš rad na aplikaciji potrebno je da u odgovoru na ovaj mejl pošaljete: 
  <br><br>
  ${documentsRequired}
  Link aplikacije: <a href = 'https://evawellbeing.app/'>https://evawellbeing.app/<a/>.
                <br><br>
                Link vebsajta:  <a href = 'https://evawellbeing.org/'>https://evawellbeing.org/<a/>.
                <br><br>
                Link od našeg Instagram profila: <a href = 'https://www.instagram.com/evawellbeing/'>https://www.instagram.com/evawellbeing/<a/>.
                <br><br>
                Ukoliko želite da učestvujete u razvoju projekta EVA, popunite kratak <a href='https://docs.google.com/forms/d/e/1FAIpQLScw63exrdwx5ykjHzq1dTyZXmBZs8DPn8Wgn25IkCp0O8S9Iw/viewform?usp=sf_link'>upitnik<a/>.
                  <br><br><br>
                  Srdačan pozdrav,
                  <br>
                  Tim Eva
              
`        };
      const result = await sendEmail(emailData);
      console.log('Email sent successfully:', result.data);
      const emailData2 = {
        to: email.trim(), // Replace with the recipient's email address
        subject: 'Pomozi nam da unapredimo aplikaciju',
        messageBody: `
                Kako bismo unapredili iskustvo naših korisnika na platformi, potrebne su nam određene povratne informacije i saveti.<br>Zbog toga bi nam značilo ukoliko bi popunio/la kratak upitnik.

                <br><br>

                <b>Link od upitnika:</b> https://forms.gle/8c2UPX3mLe6R4PBt7
                <br><br>
                
                Srdačan pozdrav!
                  <br>
                  Tim Eva
              `        };
      const result2 = await sendEmail(emailData2);
      console.log('Email sent successfully:', result2.data);
    
      
      const listOfIdsDocRef = doc(db, 'ClientsIds', listType);
      const listOfIdsDocRefAll = doc(db, 'ClientsIds', "ListOfIdsAll");
          await updateDoc(listOfIdsDocRef, {
            [`ids.${auth.currentUser.uid}`]: {
              email: email.trim(),
              name: name.trim(),
              surname: surname.trim(),
              userType: userType.trim(),
              averageRating: NaN,
              workingDays: {
                workingTime: [],
                specificDays: []
              }
            }
          });
          await updateDoc(listOfIdsDocRefAll, {
            [`ids.${auth.currentUser.uid}`]: {
              email: email.trim(),
              name: name.trim(),
              surname: surname.trim(),
              userType: userType.trim(),
              averageRating: NaN,
              workingDays: {
                workingTime: [],
                specificDays: []
              }
            }
          });
        }
       catch (err) {
      console.log(err);
      alert('Registracija nije uspela, pokušajte ponovo');
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'non_input_error'
      })
    } 
    auth.currentUser ? navigate("/") : alert('Registracija nije uspela, pokušajte ponovo'); logEvent(analytics, 'registration_error', {
      user_type: userType,
      error_type: 'non_input_error'
    })
    setSignInLoading(false);
  }
  
  const signUpClient = async () => {
    console.log('signUpClient is executed');
    setSignInLoading(true);

    if (!validateEmail(email)) {
      alert('Morate uneti validan email');
      setSignInLoading(false);
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'email'
      })
      return;
    }
  
    if (!validatePassword(password)) {
      alert("Lozinka mora sadržati najmanje 8 karaktera. Nije dozvoljeno koristiti simbole poput '<', '>', ili '&'.");
      setSignInLoading(false);
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'password'
      })
      return;
    } 
    if (!validateName(name)) {
      alert('Ime mora sadržati samo slova');
      setSignInLoading(false);
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'name'
      })
        return;
      }
    if (!validateSurname(surname)) {
      alert('Prezime mora sadržati samo slova');
      setSignInLoading(false);
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'surname'
      })
        return;
    }
    if (!privacyPoliceAccepted) {
      alert('Potrebno je da prihvatite politiku privatnosti kako biste se registrovali');
      setSignInLoading(false);
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'privacy_police'
      })
      return;
    }
    if (!termsAndConditionAccepted) {
      alert('Potrebno je da prihvatite uslove korišćenja kako biste se registrovali');
      setSignInLoading(false);
      logEvent(analytics, 'registration_error', {
        user_type: userType,
        error_type: 'terms_and_conditions'
      })
      return;
    }
       
      try {
          await createUserWithEmailAndPassword(auth, email, password);
          if(auth.currentUser)
          {
            await setDoc(doc(db,'Clients',auth.currentUser.uid), {
            email:email.trim(),
            name :name.trim(),
            surname: surname.trim(),
              privacyPoliceAccepted: privacyPoliceAccepted,
              termsAndConditionAccepted: termsAndConditionAccepted,
            userType: 'client'
            });
            const consentRef = doc(db, 'Clients', auth.currentUser.uid, 'consents', new Date().toISOString());
            await setDoc(consentRef, {
              privacyPoliceAccepted: privacyPoliceAccepted,
              termsAndConditionAccepted: termsAndConditionAccepted
              /* phone: phone, */
            });

            dispatch({
              type: "ADD_TO_BASKET",
              item: {
                email: email.trim(),
                name: name.trim(),
                surname: surname.trim(),
                userType: 'client',
                privacyPoliceAccepted: privacyPoliceAccepted,
                termsAndConditionAccepted: termsAndConditionAccepted,
                analytics_user: true
              }              
            }
            );
            if (auth.currentUser) { 
              
              setUserId(analytics, auth.currentUser.uid);
              setUserProperties(analytics, {
                user_type:'client'
              })
              logEvent(analytics,"sign_up",{
              })
              const emailData = {
                to: email.trim(), // Replace with the recipient's email address
                subject: 'Prijava na EVA aplikaciji',
                messageBody: `
                <b>Hvala na prijavi!</b> 
                <br><br>
                 Ukoliko imate bilo kakvo pitanje u vezi sa korišćenjem aplikacije, možete ga postaviti u odgovoru na ovaj mejl.  
                <br><br>
                Link aplikacije: <a href = 'https://evawellbeing.app/'>https://evawellbeing.app/<a/>.
                <br><br>
                Link vebsajta:  <a href = 'https://evawellbeing.org/'>https://evawellbeing.org/<a/>.
                <br><br>
                Link od našeg Instagram profila: <a href = 'https://www.instagram.com/evawellbeing/'>https://www.instagram.com/evawellbeing/<a/>.
                <br><br>
                Ukoliko želite da učestvujete u razvoju projekta EVA, popunite kratak <a href='https://docs.google.com/forms/d/e/1FAIpQLScw63exrdwx5ykjHzq1dTyZXmBZs8DPn8Wgn25IkCp0O8S9Iw/viewform?usp=sf_link'>upitnik<a/>.
                  <br><br><br>
                  Srdačan pozdrav!
                  <br>
                  Tim Eva
              `        };
              const result = await sendEmail(emailData);
              console.log('Email sent successfully:', result.data);
              const emailData2 = {
                to: email.trim(), // Replace with the recipient's email address
                subject: 'Pomozi nam da unapredimo aplikaciju',
                messageBody: `
                <Kako bismo unapredili iskustvo naših korisnika na platformi, potrebne su nam određene povratne informacije i saveti.<br>Zbog toga bi nam značilo ukoliko bi popunio/la kratak upitnik.

                <br><br>

                <b>Link od upitnika:</b> https://forms.gle/8c2UPX3mLe6R4PBt7
                <br><br>

                Srdačan pozdrav!
                  <br>
                  Tim Eva
              `        };
              const result2 = await sendEmail(emailData2);
              console.log('Email sent successfully:', result2.data);
              navigate("/");
              props.setNewUser(prev=>prev+1);
            } else {
              alert('Registracija nije uspela, pokušajte ponovo');
              logEvent(analytics, 'registration_error', {
                user_type: userType,
                error_type: 'non_input_error'
              })
              
          } 
        }} catch (err) {
        alert('Registracija nije uspela, pokušajte ponovo');
        logEvent(analytics, 'registration_error', {
          user_type: userType,
          error_type: 'non_input_error'
        })
            }
    setSignInLoading(false);
        }

        

        
  
  
  const signIn = async () => {
    console.log('signIn is executed');
    setSignInLoading(true);
    if (!validateEmail(email)) {
      alert('Morate uneti validan email');
      setSignInLoading(false);
      logEvent(analytics, 'login_error', {
        user_type: userType,
        error_type:'email'
      })
      return;
    }
  
    if (!validatePassword(password)) {
      alert('Password mora sadržati najmanje 8 karaktera, jedno veliko, jedno malo slovo i jedan broj');
      setSignInLoading(false);
      logEvent(analytics, 'login_error', {
        user_type: userType,
        error_type: 'password'
      })
      return;
    } 
    
 
    try {
        await signInWithEmailAndPassword(auth, email, password);
  
      if (auth.currentUser) { 
        const docRef = doc(db, "Clients", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();


        dispatch({
          type: 'SET_USER',
          user: auth.currentUser,
        });

        dispatch({
          type: "ADD_TO_BASKET",
          item: userData,

        });
              logEvent(analytics,'login',{
              });
              navigate("/");
              
      } else {
        alert('Prijava nije uspela, pokušajte ponovo'); logEvent(analytics, 'login_error', {
          user_type: userType,
          error_type: "non_input_error"
        }) } 
  }
          
         catch (err) {
          
          alert('Prijava nije uspela, pokušajte ponovo')
          
        
    }
    setSignInLoading(false);
      }
      
    const signOutM = async () => {   
        try {
            await signOut(auth);
          } catch (err) {
           alert('Prijava nije uspela, pokušajte ponovo');
    }
    }

    const handleJoyrideCallback = (data) => {
      const { status, action } = data;
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
        setRun(false); // Stops the tour
      }
      // You can add more conditions here based on your requirements.
    };
    const setClientstate1 = () => {
      setClient(true);
      setPrivacyPoliceAccepted(false);
      setTermsAndConditionAccepted(false);
      logEvent(analytics,'client_button_clicked')
}
    const setClientstate2 = () => {
      setClient(false);
      logEvent(analytics,'provider_button_clicked')
    }
    const setSignInState = () => {
      setSignInP(false);
      logEvent(analytics, 'go_from_login_to_signup', { type_selected: userType })
    }

    const onTherapistButtonClick = (event) => {
      setClientstate2();

      
  
      let selectElement;
      if(event.target.tagName.toLowerCase() === "select") {
          selectElement = event.target;
      } 
      else if(event.target.parentElement.parentElement.tagName.toLowerCase() === "button") {
          selectElement = event.target.parentElement.parentElement.querySelector('select');
          
      } 
      else if(event.target.parentElement.parentElement.parentElement.tagName.toLowerCase() === "button") {
          selectElement = event.target.parentElement.parentElement.parentElement.querySelector('select');
          
      } 
      else if(event.target.tagName.toLowerCase() === "button") {
          selectElement = event.target.querySelector('select');
      }
  
      if(!userType && selectElement) {
          setUserType(selectElement.value);
      }
      setPrivacyPoliceAccepted(false);
      setTermsAndConditionAccepted(false);
  }
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };    

  return (

    <div className='loginPageRoot'>
    <aside className='loginPageAside' > 
    <video id="background-video" autoPlay loop muted playsInline className='loginPageVideo' >
  <source src="/-e503-46c2-8220-b91676081b7a.mp4" type="video/mp4"></source>
</video >


<LogoSvg></LogoSvg>      
</aside>

<main className='loginPageMain'>
    <div className="mainContainer">
   <h1 className='signInH'>
    <p className='upperText'>{signInP? "Uloguj se":<><p onClick={openDialog}>Registruj se</p><InfoOutlinedIcon onClick={openDialog} fontSize='small'/></>}</p>
    </h1>
   <div className='upperButtons'>
    <button className='clientButton' onClick={setClientstate1} style= {client?{backgroundColor:'#7BC7BF'}:{backgroundColor:'#FFFFFF'}}>
      <div className = 'SolutionContainer1'>
      <p className='ASA'>KAO</p>
      <p className='NOTASA'>Klijent</p>
      </div>
      
      <ClientSvg className='Svg1'/>
      
      
      </button>
    <button className='therapistButton' onClick={(event)=>{onTherapistButtonClick(event)}} style= {client?{backgroundColor:'#FFFFFF'}:{backgroundColor:'#7BC7BF'}}>
    <div className='SolutionContainer2'>
      <p className='ASA2' >KAO</p>
    <select className='NOTASA2' onClick={() => logEvent(analytics,'click_provider_select_registration')}  style= {client?{backgroundColor:'#FFFFFF'}:{backgroundColor:'#7BC7BF'}} value={userType? userType : "psychotherapy"} onChange={(event) => {
                  setUserType(event.target.value); logEvent(analytics,'select_provider_type_registration') }}  >
    <option value="psychotherapy" style= {client?{backgroundColor:'#FFFFFF'}:{backgroundColor:'#7BC7BF'}}>Terapeut</option>
  <option value="savetnik" style= {client?{backgroundColor:'#FFFFFF'}:{backgroundColor:'#7BC7BF'}}>Savetnik</option>
  <option value="razgovor" style= {client?{backgroundColor:'#FFFFFF'}:{backgroundColor:'#7BC7BF'}}>Razgovor</option>
    </select>
      </div>
    <TherapistSvg className='Svg2'/>
    </button>
    </div>
    <div className='inputsDiv'>
  {
    signInP ? (
      <div className='inputs'>
        <div className='inputsDivPart'>
          <p className='inputsP'>E-mail adresa</p>  
          <input className='realInput' type='email' placeholder="e-mail adresa" autocomplete="off" onChange={(e) => setEmail(e.target.value)} onFocus={()=> logEvent(analytics,'email_input_focused_login',{type_selected:userType})}/>
        </div>
        <div className='inputsDivPart'>
          <p className='inputsP'>Lozinka</p>
          <div style={{display: 'flex'}} >
                      <input className='realInput' type={showPassword ? "text" : "password"} placeholder="lozinka" autocomplete="new-password" onChange={(e) => setPassword(e.target.value)} onFocus={() => logEvent(analytics, 'email_input_focused_login', { type_selected: userType })} />
          <img style={{position:'relative', right:'2rem'}} width="20rem" src={showPassword ? showPasswordImage : hidePasswordImage} className='passwordButton' onClick={togglePasswordVisibility}/>
          </div>
        
    
        </div>
      </div>
    ) : (
                  client ? <ClientSignUp privacyPoliceAccepted={privacyPoliceAccepted} setPrivacyPoliceAccepted={setPrivacyPoliceAccepted} termsAndConditionAccepted={termsAndConditionAccepted} setTermsAndConditionAccepted={setTermsAndConditionAccepted} showPassword={showPassword} showPasswordImage={showPasswordImage} 
                    hidePasswordImage={hidePasswordImage} togglePasswordVisibility={togglePasswordVisibility} setUserType={setUserType} email={email} setEmail={setEmail} password={password} setPassword={setPassword} name={name} setName={setName} surname={surname} setSurname={setSurname} /> 
                  
                
                    : <><PsyhotherapystSingUp userType={userType} privacyPoliceAccepted={privacyPoliceAccepted} setPrivacyPoliceAccepted={setPrivacyPoliceAccepted} termsAndConditionAccepted={termsAndConditionAccepted} setTermsAndConditionAccepted={setTermsAndConditionAccepted} showPassword={showPassword} showPasswordImage={showPasswordImage} 
      hidePasswordImage={hidePasswordImage} togglePasswordVisibility={togglePasswordVisibility} setUserType = {setUserType} email={email} setEmail={setEmail} password={password} setPassword={setPassword} name={name} setName={setName} surname={surname} setSurname={setSurname} adress={adress} setAdress={setAdress} certificate={certificate} setCertificate={setCertificate} /* phone={phone} setPhone={setPhone} *//>
      
                  </>
                )
            }
            
{ !signInLoading ? <button className='signInButton' onClick={signInP ? signIn : ((client ? signUpClient : signUpPsyhotherapyst )) }>{signInP? 'Uloguj se':'Registruj se' }</button>
              : <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "fit - content" }} role="status" aria-live="polite">
                <RingLoader color={'#4dffdb'} loading={signInLoading} size={50} aria-label="Loading Spinner" data-testid="loader" />
              </div>
}  <div style={{ display: 'flex' }}>
    <p className='P2'>{signInP? 'Nemaš svoj profil?': 'Već imaš svoj profil ?'}</p> 
    <button className='registerButton' onClick={signInP? setSignInState : backToSingIn}>{signInP? <p>Registruj se</p> : "Uloguj se"}</button>
  </div>
</div>
</div>
</main>
<InstructionsDialog
        isOpen={isDialogOpen}
        handleClose={closeDialog}
      />
      <Joyride
  continuous
  run={run} // Ensure this is set to true to start automatically
  steps={steps}
  callback={handleJoyrideCallback}
  disableOverlayClose={true}
  locale={{
    back: 'Nazad', // Unicode for right arrow
    next: 'Sledeće', // Unicode for left arrow
    close: 'Close',
    last: 'Izađi',
  }}
  
  
  
/>
    </div>
  )
}

export default LoginPage