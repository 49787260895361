import './ProfileCard.css'
import { ReactComponent as Star2 } from '../../SVGs/Star copy.svg';
import { useStateValue } from '../StateProvider';
import FormDialog from '../Dialog/Dialog';
import { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { analytics, auth,db } from '../../config/firebase-config';
import PopUp2 from "../PopUps/PopUp2"; 
import { doc, setDoc ,getDoc, updateDoc } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import image from './icons8-calendar-plus-64.png'
import image2 from './Default_pfp.jpg'
import { logEvent, setUserProperties } from 'firebase/analytics';
import { RingLoader } from 'react-spinners';

function ProfileCard(props) {
  const {name} = useParams();
  const navigate = useNavigate();
  const [{basket,spec , user, onHomeState}, dispatch] = useStateValue();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [input, setInput] = useState("");
  const [description ,setDescription] = useState(basket[0]?.description)
  const storage = getStorage();
  const [open, setOpen] = useState(false);
  const [plusMinusLoading, setPlusMinusLoading] = useState(false);
  
  function sanitizeName(name) {
    return name?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/[^0-9a-z_]/gi, '');
  }

  const sanitizedSurname = sanitizeName(basket[0]?.surname);
  const storageRef = ref(storage, `${auth.currentUser?.uid}/${sanitizedSurname}/avatar.jpg`);

  const [onHome,setOnHome] = useState();
  const [plusOrMinus,setPlusOrMinus] = useState();
  


  


  useEffect(()=>{
    const onHome = basket[0]?.onHome === 1 ? "Ukloni profil sa početne stranice" : 'Dodaj profil na početnu stranicu';
    const plusOrMinus = basket[0]?.onHome === 1 ? '-' : '+';
    setOnHome(onHome);
    setPlusOrMinus(plusOrMinus);
  },[onHomeState])

  const handleOpen2 = () => {
    setIsOpen2(true);
    logEvent(analytics,'open_short_description_dialog') 
  };

  function onMessageClick() {
    navigate('../../message');
  }

  

  const handleClose2 = async () => {
    setButtonLoading(true);
    setIsOpen2(false);
    try {
      if (auth.currentUser && auth.currentUser.uid) {
        await setDoc(doc(db, "Clients", auth.currentUser.uid), {
          description: description,
        }, { merge: true });
      }
      logEvent(analytics, "short_description_set");
      // It's important to close the popup after the loading state is handled
      
    } catch (error) {
      // Handle any errors here
      console.error(error);
    } finally {
      setButtonLoading(false);
      
    }
  };


   // Get the download URL
    /* useEffect(() => { getDownloadURL(storageRef)
    .then((url) => {
      // You can use this URL to display the image in an <img> tag
      var img = document.querySelector('.ProfileImage');
      {props.myProfile ? 
      img.src = url :
      img.src=props.image2}
      updateUserDocumentWithImageURL(auth.currentUser.uid, url);
      
    })
    .catch((error) => {
      // Handle any errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
  
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
  
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        case 'storage/unknown':
       handleClickOpen   // Unknown error occurred, inspect the server response
          break;
      }
    });}, []); */    
 
  const handleClickOpen = () => {
    setOpen(true);
    logEvent(analytics, 'open_profile_picture_dialog');
  };
  
  const handlePlusMinusClick = async () => {
    setPlusMinusLoading(true);
    let listReference;
    const documentRef = doc(db, "Clients", user.uid);
    switch(basket[0]?.userType){
      case 'razgovor': listReference = doc(db, "ClientsIds", "ListOfIds1"); break;
      case 'savetnik': listReference = doc(db, "ClientsIds", "ListOfIds2"); break;
      case 'psychotherapy': listReference = doc(db, "ClientsIds", "ListOfIds3"); break;
    }
    let listReference2 = doc(db, "ClientsIds", "ListOfIdsAll")

    try {
        const docSnapshot = await getDoc(documentRef);
      const listSnapshot = await getDoc(listReference);
      const listSnapshot2 = await getDoc(listReference2);
      console.log("docSnapshot and list snapshot", docSnapshot.exists(), listSnapshot.exists())

        if (docSnapshot.exists()) {
          const currentVal = docSnapshot.data().onHome;
          console.log(currentVal)
            

            // New value to set in Firestore based on currentVal
            const newValue = currentVal === 1 ? 0 : 1;
            await setDoc(documentRef, { onHome: newValue }, { merge: true });
            await updateDoc(listReference, {
              ids: {...listSnapshot.data().ids, 
                [auth.currentUser.uid]: {...listSnapshot.data().ids[auth.currentUser.uid],
                  onHome: newValue}               
              }
            });  
            await updateDoc(listReference2, {
              ids: {...listSnapshot2.data().ids, 
                [auth.currentUser.uid]: {...listSnapshot.data().ids[auth.currentUser.uid],
                  onHome: newValue}               
              }
            });  

            // Update local state using the newly set Firestore value
            dispatch({
              type: 'UPDATE_BASKET_PROPERTY',
              property: 'onHome', // The name of the property you want to update
              value: newValue // The new value for the property
            });

            const newOnHome = newValue === 1 ? "Ukloni profil sa početne stranice" : 'Dodaj profil na početnu stranicu';
          newValue === 1 ? logEvent(analytics, 'add_profile_to_feed') : logEvent(analytics, 'remove_profile_from_feed')
          newValue === 1 ? setUserProperties(analytics, { on_home: 'true' }) : setUserProperties(analytics, { on_home: 'false' })
            const newPlusOrMinus = newValue === 1 ? '-' : '+';
            setOnHome(newOnHome);
            setPlusOrMinus(newPlusOrMinus);


            console.log("Document updated successfully");
        } else {
            console.error("Document does not exist");
        }
    } catch (error) {
        console.error("Error updating the document: ", error);
    }

    setPlusMinusLoading(false); 

}


  function handleClickMessage() {
    if(auth.currentUser){
      navigate(`../../message/${name}`);
      logEvent(analytics, 'go_to_messages',{
        receiver_name: props.headerName,
        receiver_type: props.profileUserType,
        receiver_average_rating: props.averageRating,
        receiver_number_of_ratings: props.receiverUserData.numberOfRatings,
        receiver_number_of_ratings_given: props.receiverUserData.numberOfRatingsGiven,
        receiver_average_rating_given: props.receiverUserData.averageRatingGiven,
        receiver_spec1_exists: props.receiverUserData.spec1 ? 'true' : "false",
        receiver_spec2_exists: props.receiverUserData.spec2 ? 'true' : "false",
        receiver_spec3_exists: props.receiverUserData.spec3 ? 'true' : "false",
        receiver_long_description_set: props.receiverUserData.aboutMe ? 'true' : 'false',
        receiver_short_description_set: props.receiverUserData.description ? 'true' : 'false',
        receiver_profile_picture_set: props.receiverUserData.imageURL ? 'true' : 'false',
        receiver_set_appo: props.receiverUserData.setAppo ? 'true' : 'false',
        receiver_on_feed: props.receiverUserData.onHome ? 'true' : 'false',
        receiver_price: props.receiverUserData.price
      })
  }else{
      props.setIsOpenSimpleDialog(true);
      logEvent(analytics, 'go_to_messages',{
        error_type: 'access_denied',
        receiver_name: props.headerName,
        receiver_type: props.profileUserType,
        receiver_average_rating: props.averageRating,
        receiver_number_of_ratings: props.receiverUserData.numberOfRatings,
        receiver_number_of_ratings_given: props.receiverUserData.numberOfRatingsGiven,
        receiver_average_rating_given: props.receiverUserData.averageRatingGiven,
        receiver_spec1_exists: props.receiverUserData.spec1 ? 'true' : "false",
        receiver_spec2_exists: props.receiverUserData.spec2 ? 'true' : "false",
        receiver_spec3_exists: props.receiverUserData.spec3 ? 'true' : "false",
        receiver_long_description_set: props.receiverUserData.aboutMe ? 'true' : 'false',
        receiver_short_description_set: props.receiverUserData.description ? 'true' : 'false',
        receiver_profile_picture_set: props.receiverUserData.imageURL ? 'true' : 'false',
        receiver_set_appo: props.receiverUserData.setAppo ? 'true' : 'false',
        receiver_on_feed: props.receiverUserData.onHome ? 'true' : 'false',
        receiver_price: props.receiverUserData.price
      })
  }
}
 
  
    return (
        <div className='ProfileCard'>
        <div className ='VideoProfileCard'>
        {<img onClick = {handleClickMessage} src={props.myImage} className={`messageImage${auth.currentUser ? ' ': ' nonClickable'}`}></img>}
        <video className = 'Video' id="background-video" autoPlay loop muted playsInline  >
  <source src="/-e503-46c2-8220-b91676081b7a.mp4" type="video/mp4"></source>
</video >
{props.myProfile && basket[0].userType === 'client' || props.profileUserType ==='client' ?null:<div className='ProfileRating'>
  <Star2></Star2>
  <p>
  {props.myProfile 
    ? (basket[0].averageRating && !isNaN(basket[0].averageRating) 
    ? parseFloat(parseFloat(basket[0].averageRating).toFixed(2)) 
    : "-")
: (props.averageRating && !isNaN(props.averageRating) 
    ? parseFloat(parseFloat(props.averageRating).toFixed(2)) 
    : "-")
  }
</p>
</div>}
<div className='VideoOverlay'></div>
</div>
<div className = 'NotVideoProfileCard'>
  <h2 className='ProfileName'>{props.myProfile ? basket[0].name+' '+basket[0].surname :props.headerName }</h2>
  <p className='ProfileDescription'>{props.myProfile ? description: props.description2} { props.myProfile? <span onClick={handleOpen2} className='AddDescription'> +Dodaj opis</span>: null}</p>
          {props.myProfile && basket[0].userType !== 'client' && basket[0].setAppo ? !plusMinusLoading ? <div className='AddToHome' onClick={handlePlusMinusClick} title={onHome}>{plusOrMinus}</div> : <div className='AddToHomeLoading' onClick={handlePlusMinusClick} title={onHome}>{plusOrMinus}</div> : null }
  {
    props.myProfile && basket[0].userType === 'client' || 
    props.profileUserType === 'client' || 
    !props.appo && !props.myProfile ? null 
    : 
    <img 
        title={props.myProfile ? "'Klikom na dugme ulazite na stranicu gde možete podesiti svoje uobičajne i za dan specifične termine'" : auth.currentUser? "Zakažite online seansu" : 'Da biste zakazali seansu morate se prvo registrovati'} 
        onClick={() => props.toBooking(props.myProfile, navigate)} 
        className={`CalendarSymbol${auth.currentUser ? ' ': ' nonClickable'}`} 
        src={image}
    />
}
</div>
<div className="ProfileImageContainer"> 
<img src={props.myProfile ? basket[0]?.imageURL ?? image2 : props.image2 ?? image2}  className='ProfileImage' alt="Description" />
{ props.myProfile ? <div className='PlusContainer' onClick={handleClickOpen} >+ </div>:null}
<FormDialog open={open} setOpen={setOpen}/>
          <PopUp2 description={description} setDescription={setDescription} isOpen2={isOpen2} setIsOpen2 = {setIsOpen2} handleClose2={handleClose2} input={input} setInput={setInput} basket={basket} />
 
</div>

       </div> 
        )
}
export default ProfileCard;