import AS from "./AppointmentsSetting";
import { useState } from "react";
import { collection, doc, setDoc, getDocs,query,writeBatch, getDoc, updateDoc} from "firebase/firestore";
import { analytics, auth } from "../../config/firebase-config";
import { db } from "../../config/firebase-config";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import { logEvent, setUserProperties } from "firebase/analytics";


function ASC() {
    const navigate = useNavigate();
    const[{basket},dispatch] = useStateValue();
    const [price, setPrice] = useState(basket[0]?.price ? basket[0].price : '');
    // const [address, setAddress] = useState();
  const [account, setAccount] = useState(basket[0]?.account ? basket[0].account : '');
  const [account2, setAccount2] = useState(basket[0]?.account2 ? basket[0].account2 : '');
  const [description, setDescription] = useState(basket[0]?.description2 ? basket[0]?.description2 : '');
  const [checkBoxesState, setCheckBoxesState] = useState([0, 0, 0, 0, 0, 0, 0])
  const [buttonLoading, setButtonLoading] = useState(false);
    const hoursArray = [
      "00:00", "01:00", "02:00", "03:00", "04:00", "05:00",
      "06:00", "07:00", "08:00", "09:00", "10:00", "11:00",
      "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
      "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
    ];

    async function deleteCollection(db, parentDocRef) {
      const collectionRef = collection(parentDocRef, "WorkingTime");
      const snapshot = await getDocs(query(collectionRef));
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    }
    
    function setCheckBox(event, number) {
      const element = event.target;
      const checkBox = element.classList.contains('Hours') ? element.firstChild : element.lastChild
      if (checkBox.classList.contains('activeCheckBox')) {
          logEvent(analytics,'appo_day_unchecked',{day:number})
        checkBox.classList.remove('activeCheckBox');
          setCheckBoxesState((prevState) => {
            const newState = [...prevState];
            newState[number] = 1;
            return newState;
          });
        } else {
        checkBox.classList.add('activeCheckBox');
        logEvent(analytics, 'appo_day_checked', { day: number })
          setCheckBoxesState((prevState) => {
            const newState = [...prevState];
            newState[number] = 0;
            return newState;
          });
        }
      }

    async function submitAS() {
      setButtonLoading(true);
      let list = null;

        switch (basket[0].userType) {
            case 'razgovor':
                list = "ListOfIds1"
                break;
            case 'savetnik':
                list = "ListOfIds2"
                break;
            case 'psychotherapy':
                list = "ListOfIds3"
                break;
        }

    const parentDocRef = doc(db, "Clients", auth.currentUser.uid);
    const docRef4 = doc(db, "ClientsIds",list);
      const docRef5 = doc(db, "ClientsIds","ListOfIdsAll");

    /* const therapyFile = document.getElementById('TherapyFile1');
    const therapyFile2 = document.getElementById('TherapyFile2');
    if (!therapyFile) {
      console.error("TherapyFile1 not found!");
      return;
    }
    if (!therapyFile2) {
      console.error("TherapyFile2 not found!");
      return;
    }
   
    const therapySelectors = therapyFile.querySelectorAll('.activeCheckBox');
    const activeSelectorNames = [];
   
    therapySelectors.forEach(activeSelector=>{
      activeSelectorNames.push(activeSelector.nextElementSibling.textContent);
    });
    const therapySelectors2 = therapyFile2.querySelectorAll('.activeCheckBox');
    
   
    therapySelectors2.forEach(activeSelector=>{
      activeSelectorNames.push(activeSelector.nextElementSibling.textContent);
    }); */

    
    const accountRegex = /^[a-zA-Z0-9\s.-]+$/;

      const priceRegex = /^\d+$/;

      const descriptionRegex = /^[a-zA-Z0-9\s.,'?!-ćčšđžĆČŠĐŽ]*$/;


    
      if (!accountRegex.test(account) && account !== '/') {
        alert('Nedozvoljen format računa');
        setUserProperties(analytics,{price:price})
        logEvent(analytics, 'appo_settings_error',{
          error_type:'acount'
        })
        return;
      }
      
    
      if (!priceRegex.test(price)) {
        alert('Nedozvoljen format cene');
        logEvent(analytics, 'appo_settings_error', {
          error_type: 'price'
        })
        return;
      }

      if (!descriptionRegex.test(description)) {
        alert('Nedozvoljen format opisa');
        logEvent(analytics, 'appo_settings_error', {
          error_type: 'description'
        })
      return;
    }


    
      // Continue with form submission
    
    
    const hoursContainer = document.getElementById('HoursContainer1');
    const hoursElements = hoursContainer.querySelectorAll('.activeCheckBox');
    const hours = [];
    hoursElements.forEach((hoursElement)=>{hours.push(hoursElement.nextElementSibling.textContent)
    })
    
    const dayPicker = document.querySelector('.DayPicker');
    const boxes = dayPicker.querySelectorAll('.activeCheckBox');
    await deleteCollection(db, parentDocRef);
    let days = []
    boxes.forEach(async box => {
      let day = box.previousElementSibling.textContent;
      days.push(day);
      await setDoc(doc(collection(parentDocRef, "WorkingTime"), day), {
        hours: hours
      });

    })
    await setDoc(parentDocRef, { account:account, price:price, setAppo:true, account2:account2, description2:description, workingDays: {...basket[0].workingDays, workingTime:days }  }, { merge: true });
    
      // Use setDoc with merge: true to update the document or create it if it doesn't exist
      await updateDoc(docRef4, {
        [`ids.${auth.currentUser.uid}.workingDays.workingTime`]: days
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
          logEvent(analytics, 'appo_settings_error', {
            error_type: 'non_input_error'
          })
        });
      await updateDoc(docRef5, {
        [`ids.${auth.currentUser.uid}.workingDays.workingTime`]: days
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
          logEvent(analytics, 'appo_settings_error', {
            error_type: 'non_input_error'
          })
        });
      
      navigate('../appointmentsSetting2');
      logEvent(analytics, 'schedule_set');
      dispatch({
        type: 'UPDATE_BASKET_PROPERTY',
        property: 'setAppo', // The name of the property you want to update
        value: true // The new value for the property
      });
      setButtonLoading(false);
    }  
  
return (
    <AS buttonLoading = {buttonLoading} description={description} setDescription={setDescription} account2={account2} setAccount2={setAccount2} price={price} setPrice={setPrice} /* adress={setAddress} */ account={account} /* setAddress={setAddress} */ setAccount={setAccount} submit={submitAS}  checkBoxesState={checkBoxesState} setCheckBox = {setCheckBox} hoursArray = {hoursArray} />
)
}
export default ASC;
