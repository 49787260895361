import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Manual.css";
import { useStateValue } from "../StateProvider";
import notesImage from './Screenshot 2024-11-18 150753 1.png';

const Manual = () => {

    const [{ basket }] = useStateValue();
    const username = basket[0].name + ' ' + basket[0].surname;
    const testUser = ["Nikola Pejdić"].includes(username)

    return (
    <div className="Manual">
            <Header name={basket[0].name} surname={basket[0].surname} />
            { testUser?
                <div className="manualText">
                    <>
                        <br/><h1> Uputstvo 1.0</h1><br/><br/>   
                        <p>
                            Ovo uputstvo nije procedura koju treba verno pratiti niti je skup istina
                            koje se nužno moraju uzeti u obzir prilikom procesa razgovora o mentalnim
                            teškoćama. Takva vrsta uputstva, koja se može pronaći u gotovo svim
                            psihološkim modalitetima, je pseudonaučna, a na ovo treba gledati kao na
                            instrukcije kojima se vode ekonomisti u svojim praksama.{" "}
                        </p><br/> 
                        <p>
                            Ovo uputstvo je skup faktora, hipoteza i saveta koji su kreirani korišćenjem
                            statističkih procedura i rigoroznih empirijskih istraživanja. Ono ne
                            pokušava da svrsta simptome u sindrome i poremećaje niti se različite
                            kategorije faktora posmatraju kao stvarno, esencijalno odvojene nekim
                            prirodnim mehanizmom.{" "}
                        </p><br/> 
                        <p>
                            Vi niste lekari i potrebno je iz praktičnih kao i etičkih razloga objasniti
                            ovu činjenicu svakom klijentu. To je posebno bitno budući da su pseudonaučne
                            prakse psihoterapije i kliničke psihologije dovele običnog čoveka u zabludu
                            kada je u pitanju terapija razgovorom tako da čovek danas odlazi kod
                            psihoterapeuta, savetnika i sl. misleći da iza njih stoji nauka snage
                            medicine.{" "}
                        </p><br/> 
                        <p>
                            Ovo uputstvo koristite kao i sve drugo, dakle, kao korisno sredstvo za
                            unapređenje svoje prakse. Vi, vaši talenti, intuicija, empatija, emotivna
                            kontrola i sl. su ono što donosi efekat, a date instrukcije iskoristite kako
                            biste poboljšali sebe u situaciji razgovora.
                        </p><br/> 
                        <p>
                            One više služe da opišu šta je potrebno uraditi sa klijentima, a manje
                            govore o tome kako to uraditi. Način rada sa klijentima u velikoj meri
                            zavisi od terapeutovih crta ličnosti i različitih sposobnosti zbog čega je
                            ključno da svaki ponuđač usluge razgovora kreira svoj sopstveni pristup.
                        </p><br/> 
                       <br/><h1  id="faktori-uspe-nog-razgovora-o-mentalnim-te-ko-ama">
                            Faktori uspešnog razgovora o mentalnim teškoćama
                        </h1><br/><br/> 
                        <p>
                            Glavni faktori poboljšanja psihičkog zdravlja kod klijenata se mogu svrstati
                            pod tri glavne kategorije:{" "}
                        </p><br/> 
                        <ol>
                            <li><b>fiziološko stanje</b>,</li><br/>
                            <li><b>napuštanje ideje o postojanju i bitnosti lične vrednosti</b> i</li><br/>
                            <li><b>kontrola impulsa</b>.</li><br/>
                        </ol><br/> 
                        <br/><h2 id="fiziolo-ko-stanje">Fiziološko stanje</h2><br/> 
                        <p>
                            Fiziološko stanje je možda i najbitnija grupa faktora na koju treba obratiti
                            pažnju s obzirom da se od svih drugih faktora ova grupa pokazala kao
                            najlakša za promenu kod klijenata. Čini se da je relativno lakša promena
                            ovih faktora izazvana znatno manjim ,,odbranama" klijenata u odnosu na sve
                            druge faktore.{" "}
                        </p><br/> 
                        <p>
                            Ovu grupu faktora smo nazvali <strong>fiziološko stanje</strong> iz razloga
                            što se čini da su faktori nekako povezani sa zdravljem tela i fiziološkim
                            balansom organizma iako, naravno, razlozi mogu biti znatno drugačiji.
                        </p><br/> 
                        <p>
                            Faktori iz ove grupe na koje treba obratiti pažnju, od najbitnih ka manje
                            bitnim, su:
                        </p><br/> 
                        <ol>
                            <li>
                                <strong>spavanje</strong>,
                            </li><br/>
                            <li>
                                <strong>fizička aktivnost</strong>,
                            </li><br/>
                            <li>
                                <strong>konzumacija psihoaktivnih supstanci</strong>,
                            </li><br/>
                            <li>
                                <strong>ishrana</strong> i
                            </li><br/>
                            <li>
                                <strong>zdravo okruženje</strong>.
                            </li><br/>
                        </ol><br/> 
                        <br/><h3 id="spavanje">Spavanje</h3><br/>
                        <p>
                            Ubedljivo najbitniji fiziološki faktor jeste spavanje. Većina ljudi loše
                            spava, a neverovatna je nesvesnost uticaja lošeg sna na psihu budući da su
                            efekti očigledni čak i na perceptivnom planu.{" "}
                        </p><br/> 
                        <p>
                            Fokus na popravljanje klijentovog sna je stoga često front na kome se mogu
                            stvoriti značajni pozitivni efekti u klijentovom psihološkom funkcionisanju.
                        </p><br/> 
                        <p>
                            Prema istraživanjima dobrog sna idealno ponašanje čoveka, ukoliko želi dobar
                            san, svodi se na sledeće faktore od najbitnijih ka manje bitnim:
                        </p><br/> 
                        <ol>
                            <li>
                                <strong>količina sna</strong>,
                            </li><br/>
                            <li>
                                <strong>broj otkucaja srca u mirnom stanju pre uspavljivanja</strong>,
                            </li><br/>
                            <li>
                                <strong>konstantnost satnice odlaska u krevet i buđenja</strong>,
                            </li><br/>
                            <li><strong>buka i osvetljenost mesta za spavanje</strong>,</li><br/>
                            <li>
                                <strong>vreme odlaska u krevet</strong>,
                            </li><br/>
                            <li>
                                <strong>temperatura sobe tokom spavanja</strong>,
                            </li><br/>
                            <li>
                                <strong>misaona neaktivnost pre odlaska u krevet</strong> i
                            </li><br/>
                            <li>
                                <strong>podešenost cirkadijalnog ritma</strong>.
                            </li><br/>
                        </ol><br/> 
                        <br/><h4 id="koli-ina-sna">Količina sna</h4><br/>
                        <p>
                            <strong>Sedam do devet sati sna</strong>, u zavisnosti od osobe do osobe
                            (pre svega od starosti), idealna je količina sna. Sve preko, a posebno sve
                            ispod ovoga je loše i treba izbegavati.{" "}
                        </p><br/> 
                        <p>
                            Bitno je klijentima pokazati kako je malo stvari zaista bitnije od vremena
                            provedenog u snu. Recimo, mnogi studenti koji nemaju mogućnost da legnu pre
                            ponoći svesno će spavati manje kako bi otišli na jutarnje predavanje. Takvo
                            ponašanje je uglavnom iracionalno s obzirom da predavanje (osim ako nije
                            obavezno...) nije bitnije od fizičke, mentalne i emotivne spremnosti tokom
                            dana.
                        </p><br/> 
                        <p>
                            Koristite autoritet nauke po ovom pitanju budući da gotovo da nema
                            istraživanja koje ne pokazuje ogromne negativne efekte čak i 6 sati spavanja
                            u odnosu na 8 kada su u pitanju kognitivno funkcionisanje i emotivno
                            blagostanje.
                        </p><br/> 
                        <p>
                            Mnogi klijenti će tvrditi kako na njih ne utiče to koliko spavaju i kako oni
                            dobro funckionišu sa manje sati sna. Ovu iluziju je bitno razbiti ukoliko je
                            moguće, jer je verovatnoća istinitosti ove tvrdnje gotovo nepostojeća.
                        </p><br/> 
                        <br/><h4 id="broj-otkucaja-srca-u-mirnom-stanju-pre-uspavljivanja">
                            Broj otkucaja srca u mirnom stanju pre uspavljivanja
                        </h4><br/>
                        <p>
                            Idealno bi bilo kada bi se klijent nakon najviše 9 sati (a često i manje)
                            budio bez alarma potpuno odmoran. Naravno, retko ko to može da postigne čak
                            i ako redovno spava svojih 7 do 9 sati budući da je osim količine sna bitan
                            i kvalitet sna na koji uobičajen život u našoj kulturi ima vrlo negativne
                            efekte.
                        </p><br/> 
                        <p>
                            Glavni faktor koji određuje kvalitet sna i lakoću uspavljivanja jeste broj
                            otkucaja srca u mirnom stanju pre uspavljivanja koji bi trebalo biti što je
                            moguće niži. Tri glavna faktora za smanjenje broja otkucaja srca pre
                            spavanja su:
                        </p><br/> 
                        <ol>
                            <li>konzumiranje psihoaktivnih supstanci,</li><br/>
                            <li>vreme i količina poslednjeg obroka i</li><br/>
                            <li>stres i anksioznost pre i tokom uspavljivanja.</li><br/>
                        </ol><br/> 
                        <h4 id="konzumiranje-psihoaktivnih-supstanci-i-uticaj-na-broj-otkucaja-srca">
                            Konzumiranje psihoaktivnih supstanci i uticaj na broj otkucaja srca
                        </h4><br/>
                        <p>
                            Ovaj faktor smo izdvojili kao jedan od faktora fiziološkog stanja na istom
                            hijerarhijskom nivou kao spavanje s obzirom da konzumiranje psihoaktivnih
                            supstanci negativno utiče na mnogo toga pored broja otkucaja srca pre
                            spavanja.{" "}
                        </p><br/> 
                        <p>
                            Što se tiče spavanja, najpopularnije psihoaktivne supstance koje ljudi
                            masovno koriste, a koje imaju velike efekte na porast otkucaja srca pre
                            uspavljivanja su <strong>cigarete</strong> i <strong>kafa</strong>.
                        </p><br/> 
                        <ul>
                            <li>
                                Kafu ne treba konzumirati <strong>barem 6 sati</strong>, a idealno{" "}
                                <strong>8</strong> <strong>sati</strong> pre spavanja.{" "}
                            </li><br/>
                            <li>
                                Cigarete ne treba konzumirati barem <strong>2 sata</strong> pre spavanja.
                            </li><br/>
                        </ul>
                        <p>
                            Dovođenje klijenta do postepenog približavanja ovim granicama je cilj budući
                            da je, recimo, kafa dva sata pre spavanja lošija od kafe četiri sata pre
                            spavanja.
                        </p><br/> 
                        <h4 id="vreme-i-koli-ina-poslednjeg-obroka">
                            Vreme i količina poslednjeg obroka
                        </h4><br/>
                        <p>
                            {" "}
                            Što je obrok vremenski bliži i kalorijski obimniji, to će srcu biti potrebno
                            više vremena da se vrati na stabilno nizak broj otkucaja. Za manje-više bilo
                            koji obrok 6 sati je dovoljno da se neutrališu efekti na broj otkucaja, pa
                            se preporučuje da poslednji obrok bude 6 sati pre spavanja. Naravno, kao i
                            za prethodno, 2 sata pre spavanja je bolje nego 1 sat pre spavanja.
                        </p><br/> 
                        <h4 id="stres-i-anksioznost-pre-i-tokom-uspavljivanja">
                            Stres i anksioznost pre i tokom uspavljivanja
                        </h4><br/>
                        <p>
                            U suštini, to koliko će neko imati stresa i anksioznosti pre uspavljivanja
                            je u snažnoj vezi sa uobičajenim nivoom stresa i anksioznosti tokom dana što
                            se rešava na milion različitih načina.{" "}
                        </p><br/> 
                        <p>
                            Ipak, jedna dobra tehnika za smanjenje anksioznosti pre uspavljivanja svodi
                            se na klijentovo razumevanje bitnosti sna i identifikovanje svoje ličnosti
                            kao ,,profesionalnog spavača" svaki put kada dođe vreme za spavanje. Dakle,
                            klijent sat vremena pred spavanje treba da identifikuje sebe kao osobu koju
                            zanima isključivo dobar san i sve ostalo je nešto čime će se baviti kasnije
                            u nekom svom drugom stanju, recimo, kada se probudi.
                        </p><br/> 
                        <br/><h4 id="konstantnost-satnice-odlaska-u-krevet-i-bu-enja">
                            Konstantnost satnice odlaska u krevet i buđenja
                        </h4><br/>
                        <p>
                            Telo i um vole rutinu u kojoj su dnevni ciklusi najbitniji. Podela života na
                            dane se telesno najverovatnije reguliše praćenjem momenta višesatnog gašenja
                            svesnosti i ponovnog uspostavljanja svesnog stanja zbog čega se osećamo
                            toliko izgubljeno kada poremetimo ovaj sistem višesatnim spavanjem tokom
                            dana.
                        </p><br/> 
                        <p>
                            Od krucijalne je važnosti održati relativno stabilno vreme buđenja i
                            ustajanja. Varijacije od 60 minuta gore-dole su dozvoljene, sve ostalo je
                            previše. Ukoliko je recimo prosečno vreme odlaska u krevet 22:00h, a
                            prosečno vreme buđenja 06:00h, onda bi najbolje bilo odlaziti u krevet
                            između 21:00h i 23:00h, a buditi se između 05:00h i 07:00h.{" "}
                        </p><br/> 
                        <p>
                            Takođe, trebalo bi izbegavati popodnevne dremke osim u situacijama velikog
                            kognitivnog ili fizičkog napora gde bi kratka dremka do 20 minuta imala
                            značajne benefite na fizičko/kognitivno funkcionisanje, budnost i
                            raspoloženje.
                        </p><br/> 
                        <br/><h4 id="buka-i-osvetljenost-mesta-za-spavanje">
                            Buka i osvetljenost mesta za spavanje
                        </h4><br/>
                        <p>
                            Savremeni čovek je navikao da bude okružen bukom i raznolikim svetlosnim
                            stimulacijama. Mnogi se uspešno uspavljuju uz upaljen televizor, muziku na
                            slušalicama... Ipak, uspešno uspavljivanje nije isto što i uspešno spavanje.
                            Čini se da je nemoguće neutralisati efekte koje buka i svetlost imaju na
                            spavanje. Jedini način jeste uklanjanje viška stimulacija.
                        </p><br/> 
                        <p>
                            Prosečna buka noću u gradovima obično prevazilazi granice koje su optimalne
                            za spavanje. Zbog toga je korišćenje čepića za uši ili nekog sličnog
                            sredstva tokom spavanje odličan izbor dovoljan za rešavanje problema buke u
                            većini slučajeva.
                        </p><br/> 
                        <p>
                            Što se tiče svetlosti, spavanje pod upaljenim svetlom, lampom ili slično
                            utiče na san značajno zbog čega klijentu treba objasniti da takve prakse
                            treba izbegavati. Rad na ovom planu je posebno težak sa klijentima koji pate
                            od različitih fobija.
                        </p><br/> 
                        <p>
                            Količina svetlosti koja upada u prozore od gradskog osvetljenja ili sa
                            drugih terasa je obično prevelika. Zbog toga bi idealno rešenje bilo
                            postavljanje žaluzina ili neke druge sprave koja bi zamračila sobu, a sve u
                            zavisnosti od klijentovog budžeta.{" "}
                        </p><br/> 
                        <br/><h4 id="vreme-odlaska-u-krevet">Vreme odlaska u krevet</h4><br/>
                        <p>
                            Idealno vreme odlaska u krevet je ono koje bi nam omogućilo što veću
                            količinu mraka tokom sna. Ukoliko recimo sunce izlazi u 6 ujutru, idealno bi
                            bilo da legnemo pre 22h.
                        </p><br/> 
                        <p>
                            Mnogi klijenti nisu u mogućnosti da legnu rano, pa se ta problematika može
                            rešiti na drugi način. Potpunim zamračivanjem sobe tokom spavanja moguće je
                            neutralisati većinu negativnih efekata kasnog odlaska u krevet.
                        </p><br/> 
                        <p>
                            Preporučuje se postepeno podešavanje vremena odlaska u krevet tako što se
                            vreme odlaska pomera za pola sata svakog dana sve dok se ne dođe do
                            optimalnog vremena.
                        </p><br/> 
                        <br/><h4 id="temperatura-tokom-spavanja">Temperatura tokom spavanja</h4><br/>
                        <p>
                            Optimalna temeperatura tokom spavanja je negde oko 18 stepeni celzijusa.
                            Ipak, održavanje idealne temperature u sobi za spavanje ume da bude
                            komplikovano, bučno i skupo, pa stoga nije potrebno preterano insistirati na
                            ovom faktoru ukoliko se javi neki od ovih problema.
                        </p><br/> 
                        <br/><h4 id="misaona-neaktivnost-pre-spavanja">
                            Misaona neaktivnost pre spavanja
                        </h4><br/>
                        <p>
                            Već smo naveli kako su stres i anskioznost bitni faktori kada je u pitanju
                            dobar san. Ovde se ipak ne misli na takvu vrstu misaone aktivnosti, već na
                            klasično, čak i pozitivno razmišljanje. Svaka misaona aktivnost pred
                            spavanje otežava uspavljivanje, pa je stoga bolje opustiti se i ne
                            razmišljati ni o čemu komplikovanom barem pola sata pred spavanje.{" "}
                        </p><br/> 
                        <p>
                            Dobar način za to je čitanje neke lagane knjige ili topla kupka pre odlaska
                            u krevet.
                        </p><br/> 
                        <br/><h4 id="pode-enost-cirkadijalnog-ritma">Podešenost cirkadijalnog ritma</h4><br/>
                        <p>
                            Organizam se uspavljuje kada doživljava da je pao mrak, a budi se kada se
                            pojavi prva svetlost. Zbog toga je bitno da pre uspavljivanja ne budemo
                            izloženi plavoj ili beloj svetlosti. Preporučuje se da se ne gleda ni u
                            jedan digitalni ekran barem pola sata, a najbolje sat vremena pred spavanje.
                            Osim toga dobro je i sat vremena pred spavanje ne biti izložen sijalicama
                            bele boje koje se u idealnom slučaju mogu zameniti sijalicama toplijih boja
                            poput crvene ili narandžaste.{" "}
                        </p><br/> 
                        <p>
                            Treba izbegavati snuzovanje alarma i vraćanje u krevet. Dobra taktika je
                            staviti alarm na udaljeno mesto kako bi za njegovo gašenje bilo neophodno
                            ustati iz kreveta.{" "}
                        </p><br/> 
                        <p>
                            U prvih 15 minuta nakon buđenja bilo bi dobro na kratko (par minuta) izaći
                            na terasu ili u dvorište kako bismo bili izloženi dnevnoj svetlosti.
                        </p><br/> 
                        <br/><h3 id="fizi-ka-aktivnost">Fizička aktivnost</h3><br/>
                        <p>
                            Ogroman uticaj fizičke aktivnosti na psihološko zdravlje je još jedna od
                            empirijski snažno utvrđenih činjenica.{" "}
                        </p><br/> 
                        <p>
                            Ukoliko fizičku aktivnost vršimo zbog psihološkog zdravlja, postoje četiri
                            glavna faktora na koja je potrebno obratiti pažnju:
                        </p><br/> 
                        <ol>
                            <li>
                                <strong>konstantnost</strong>,
                            </li><br/>
                            <li>
                                <strong>trajanje</strong>,
                            </li><br/>
                            <li>
                                <strong>intenzitet</strong> i
                            </li><br/>
                            <li>
                                <strong>zabava</strong>.
                            </li><br/>
                        </ol><br/> 
                        <br/><h4 id="konstantost-fizi-ke-aktivnosti">Konstantost fizičke aktivnosti</h4><br/>
                        <p>
                            Konstantnost fizičke aktivnosti je najbitniji od navedenih faktora. Odnosi
                            se na proporciju dana u kojima vršimo fizičku aktivnost. U idealnom slučaju
                            za mentalno zdravlje fizička aktivnost bi trebalo postojati svakog dana bez
                            preskakanja.
                        </p><br/> 
                        <p>
                            Veoma je korisno uticati na klijenta da svakog dana vrši određenu fizičku
                            aktivnost, pa bila ona čak i kratka šetnja. Najbolji način za to je uvrstiti
                            fizičku aktivnost u određeni plan ili rutinu, a posebno bi bilo korisno
                            ukoliko bi fizička aktivnost bila vršena kratko nakon buđenja.
                        </p><br/> 
                        <br/><h4 id="trajanje-fizi-ke-aktivnosti">Trajanje fizičke aktivnosti</h4><br/>
                        <p>
                            Idealno bi bilo kada bi fizička aktivnost trajala sat vremena ili više, mada
                            sve preko sata ne dodaje na efektima. Ukoliko klijent ima nepredvidiv
                            raspored, korisno bi bilo podeliti fizičku aktivnost na više manjih.{" "}
                        </p><br/> 
                        <p>
                            Naravno, 30 minuta je bolje nego 15, a bolje je odraditi i 2 dana po pola
                            sata nego jednog dana sat vremena a drugog ništa.
                        </p><br/> 
                        <br/><h4 id="intenzitet-fizi-ke-aktivnosti">Intenzitet fizičke aktivnosti</h4><br/>
                        <p>
                            Idealno bi bilo zadisati se nekoliko puta tokom fizičke aktivnosti. Dobar
                            način da se proceni da li smo odradili aktivnost optimalnog intenziteta je
                            test ,,pričanja tokom aktivnosti" gde je cilj da se barem nekoliko puta
                            tokom aktivnosti zadišemo do te mere da je pričanje znatno otežano.
                        </p><br/> 
                        <br/><h4 id="zabava-tokom-fizi-ke-aktivnosti">Zabava tokom fizičke aktivnosti</h4><br/>
                        <p>
                            Poželjno je da vrsta fizičke aktivnosti bude zabavna klijentu. Iako su sa
                            zdravstvene strane kao i iz perspektive psiholoških efekata najbolje
                            kombinacija teretane i džoginga, u realnosti je većini klijenata ova vrsta
                            treninga previše naporna i dosadna. Stoga je potrebno pronaći fizičke
                            aktivnosti u kojima klijent najviše uživa ili u većini slučajeva najmanje
                            ,,ne uživa".
                        </p><br/> 
                        <br/><h3 id="konzumacija-psihoaktivnih-supstanci">
                            Konzumacija psihoaktivnih supstanci
                        </h3><br/>
                        <p>
                            Jedan od najboljih načina da poremetimo fiziološko funkcionisanje mozga je
                            konzumiranje psihoaktivnih supstanci. Mozak, čini se, voli mir i rutinu, a
                            supstance ove vrste previše intenzivno utiču na moždane promene.{" "}
                        </p><br/> 
                        <p>
                            Podelili smo supstance prema pristupu koji je moguće imati u radu sa
                            klijentima kada je u pitanju skidanje sa tih supstanci:
                        </p><br/> 
                        <ol>
                            <li>
                                <strong>popularne legalne supstance</strong>,
                            </li><br/>
                            <li>
                                <strong>ilegalne lake droge</strong>,
                            </li><br/>
                            <li>
                                <strong>ilegalne teške droge</strong> i
                            </li><br/>
                            <li>
                                <strong>psihijatrijski lekovi</strong>.
                            </li><br/>
                        </ol><br/> 
                        <br/><h4 id="popularne-legalne-supstance">Popularne legalne supstance</h4><br/>
                        <p>
                            Najpoznatije legalne psihoaktivne supstance su <strong>kofein</strong>,{" "}
                            <strong>nikotin</strong> i <strong>alkohol</strong>.
                        </p><br/> 
                        <h4 id="kofein">Kofein</h4><br/>
                        <p>
                            Već smo videli kako kofein negativno utiče na spavanje i na koje načine je
                            moguće sprečiti efekat kofeina kada je spavanje u pitanju.{" "}
                        </p><br/> 
                        <p>Konzumente kofeina ćemo u našu svrhu svrstati u dve kategorije:</p><br/> 
                        <ol>
                            <li>klijenti koji unose preko 5mg kofeina po kilogramu težine i</li><br/>
                            <li>klijenti koji unose ispod 5mg kofeina po kilogramu težine.</li><br/>
                        </ol><br/> 
                        <h4 id="klijenti-koji-unose-preko-5mg-kofeina-dnevno-po-kilogramu-te-ine">
                            Klijenti koji unose preko 5mg kofeina dnevno po kilogramu težine
                        </h4><br/>
                        <p>
                            Ovo je ekvivalentno količini od preko 3 šolje kafe ili 3 manja energetska
                            pića dnevno. Svaka osoba koja unosi ove količine kofeina ima psihološke
                            teškoće zbog njegovog unosa i u ovom slučaju je uvek cilj smanjiti unos
                            kofeina.
                        </p><br/> 
                        <p>
                            Mnogima je potrebno objasniti i naglasiti kako kofein ima intenzivne
                            posledice kada su u pitanju dizanje krvnog pritiska, anksioznost, san i
                            promene raspoloženja.
                        </p><br/> 
                        <p>
                            Sa druge strane mnogi koriste kofein iz razloga što spavaju loše, pa je rad
                            na snu indirektno koristan i za borbu protiv kofeinske zavisnosti.
                        </p><br/> 
                        <h4 id="klijenti-koji-unose-ispod-5mg-kofeina-dnevno-po-kilogramu-te-ine">
                            Klijenti koji unose ispod 5mg kofeina dnevno po kilogramu težine
                        </h4><br/>
                        <p>
                            Ova količina unosa utiče na promene raspoloženja kod većine klijenata, ali
                            efekat verovatno nije previše visok. Bitno je, ako ništa drugo, objasniti
                            klijentima da se poslednja kafa pije najkasnije 8 sati pre spavanja.
                        </p><br/> 
                        <p>
                            Dobra taktika je i pokušaj zamene kafe sa{" "}
                            <strong>crnim ili zelenim čajem</strong> koji sadrže znatno manje kofeina.
                        </p><br/> 
                        <p>
                            Jedini slučaj u kome je korisno staviti fokus na ovaj faktor odmah (već u
                            prvim seansama) u ovoj grupi klijenata jeste ukoliko klijent ima izraženije{" "}
                            <strong>probleme sa anksioznošću</strong>. Tada je bilo koja količina
                            kofeina destruktivna za psihičko zdravlje.
                        </p><br/> 
                        <h4 id="nikotin">Nikotin</h4><br/>
                        <p>
                            Nikotin, koji se najčešće unosi preko cigareta, destruktivan je u svakom
                            obliku i za svakoga kada je u pitanju psihičko zdravlje (fizičko je posebna
                            priča). Najbolji način da se ostavi nikotin je naglo, u trenutku relativne
                            psihičke stabilnosti.{" "}
                        </p><br/> 
                        <p>
                            To znači da se najbolja taktika uticaja na klijenta kada je u pitanju
                            ostavljanje nikotina sastoji iz 3 faze:
                        </p><br/> 
                        <ol>
                            <li>postizanje relativne emotivne stabilnosti i/ili stabilnih uslova,</li><br/>
                            <li>naglo ostavljanje nikotina bez pokušaja smanjivanja doze i</li><br/>
                            <li>
                                održavanje nepušačkog statusa, najbolje odvraćanjem klijenta od odlaska na
                                mesta gde se puši kao i druženja sa ljudima koji konzumiraju nikotin tokom
                                barem nekoliko meseci.
                            </li><br/>
                        </ol><br/> 
                        <p>
                            Često su nikotinski zavisnici povratnici, pa je stoga potrebno ove cikluse
                            ponoviti nekoliko puta gde se očekuje da će svaki sledeći put biti lakši od
                            prethodnog.
                        </p><br/> 
                        <h4 id="alkohol">Alkohol</h4><br/>
                        <p>
                            Alkohol je <strong>teška droga</strong>. Bez obzira na njegovu popularnost i
                            dostupnost na konzumiranje alkohola treba gledati kao na konzumiranje
                            kokaina ili neke druge teške droge. Svaka količina alkohola je pogubna za
                            psihološko zdravlje i zdravlje organizma u celosti.
                        </p><br/> 
                        <p>
                            Nažalost, kod nas postoji kultura romantizacije alkohola od koje najčešće
                            zavisnici ili relativno česti konzumeri alkohola pate. Ukoliko primetite da
                            klijent konzumira alkohol svakodnevno ili skoro svakodnevno, napuštanje te
                            navike jedan je od najvećih ako ne i najveći prioritet procesa rada sa
                            klijentom.
                        </p><br/> 
                        <p>
                            Sa ,,vikend" alkoholičarima takođe treba raditi intenzivno na uklanjanju te
                            navike budući na intenzivne negativne efekte takvog ponašanja.
                        </p><br/> 
                        <h4 id="ilegalne-lake-droge">Ilegalne lake droge</h4><br/>
                        <p>
                            Najpopularnija laka droga iz ove grupe je <strong>kanabis</strong>, ali
                            klijenti često koriste sledeće droge: <strong>MDMA</strong>,{" "}
                            <strong>LSD</strong>, <strong>psilocibin, ketamin...</strong>
                        </p><br/> 
                        <p>
                            Iako postoji mali broj istraživanja koji govori o relativno pozitivnim
                            efektima nekih od ovih droga na psihičko zdravlje kada se koriste u
                            terapiji, većina tih istraživanja su, naravno, nereplikabilna i kao i većina
                            stvari u kliničkoj psihologiji <strong>pseudonaučna</strong> tako da
                            najverovatnije ne postoji način da ove droge budu iskorišćene u terapiji.
                            Osim toga, to je kod nas ilegalno!
                        </p><br/> 
                        <p>
                            Svako relativno metodološki snažno istraživanje govori o negativnim efektima
                            samostalnog korišćenja bilo koje od ovih droga, a ideje o ,,razbijanju ega"
                            na supstancama kao što je LSD su samo nedokazane hipi fantazije.{" "}
                        </p><br/> 
                        <p>
                            Stoga jedan od bitnih frontova uspešnog procesa je sprečavanje klijenata u
                            konzumiranju ovih droga.
                        </p><br/> 
                        <p>
                            Bitno je razumeti razloge zbog kojih klijenti koriste ove droge i obavezno
                            delovati drugarski, a ne osuđujuće zbog njihovog konzumiranja.
                        </p><br/> 
                        <h4 id="ilegalne-te-ke-droge">Ilegalne teške droge</h4><br/>
                        <p>
                            Droge ovog tipa u koji spadaju{" "}
                            <strong>heroin, morfijum, amfetamin, krek i sl.</strong> su droge koje
                            ekstremno negativno utiču na mentalno zdravlje klijenata.
                        </p><br/> 
                        <p>
                            Ne očekujte da će klijenti priznati korišćenje ovih droga pre nego što
                            steknu potpuno poverenje u vas, ali u momentu kada saznate da to koriste,
                            pokušaj uticaja na ostavljanje ovih droga je prioritet.
                        </p><br/> 
                        <br/><h4 id="psihijatrijski-lekovi">Psihijatrijski lekovi</h4><br/>
                        <p>
                            Nažalost, pseudonaučno psihijatrijsko delovanje kada je u pitanju rešavanje
                            mentalnih problema (kada se isključe psihoze) uzelo je maha svuda u svetu
                            kao i u zakonima. Ne postoje dokazi da lekovi za depresiju, anksioznost i
                            raspoloženje pomažu, ali postoje dokazi da oni izazivaju zavisnost,
                            halucinacije i slične efekte drugih droga. Ove lekove treba posmatrati kao
                            lake i teške droge i pronaći način da klijent prekine sa njihovim
                            korišćenjem. To je posebno teško budući da je to ilegalno raditi direktno
                            pošto se u zakonu, ukoliko je klijent legalno dobio lekove (što često nije
                            slučaj), to posmatra kao da skidate klijenta sa lekova za, recimo,
                            dijabetes. Zbog toga je potrebno naći indirektne načine da se to uradi.{" "}
                        </p><br/> 
                        <br/><h3 id="ishrana">Ishrana</h3><br/>
                        <p>
                            Poslednja grupa fizioloških faktora na koje je potrebno obratiti pažnju je
                            ishrana.
                        </p><br/> 
                        <p>U suštini, pravila ishrane za mentalno zdravlje su:</p><br/> 
                        <ol>
                            <li>
                                izbegavati obimne obroke s obzirom da oni smanjuju energiju, uspavljuju i
                                dižu krvni pritisak,
                            </li><br/>
                            <li>
                                izbegavati namirnice sa puno soli, šećera i kalorija poput suhomesnatog,
                                slatkiša, slatkih i gaziranih sokova, slaniša, previše masne i zasoljene
                                hrane i sl. i{" "}
                            </li><br/>
                            <li>
                                doručak treba da bude najobimniji obrok (najbolje visoko proteinski) dok
                                bi večera u idealnom slučaju trebalo da bude 6 sati pre spavanja i
                                najmanje obiman obrok.
                            </li><br/>
                        </ol><br/> 
                        <br/><h3 id="zdravo-okru-enje">Zdravo okruženje</h3><br/>
                        <p>
                            Teško je spavati dobro ako svi u kući ležu kasno. Teško je jesti dobro ako
                            svi u kući jedu samo slatko i suhomesnato. Teško je ne uzimati alkohol i
                            cigarete ako u našem društvu svi piju i puše.
                        </p><br/> 
                        <p>
                            Potrebno je ispitati okruženje klijenta, napraviti jasnu sliku i gurati
                            klijenta u smeru zdravijih okruženja kojima on ima pristup. Ukoliko klijent
                            ima društvo koje redovno ide na fudbal i drugo društvo koje svakog dana ide
                            na žurke i loše spava, za klijenta je korisno da se približi prvoj, a udalji
                            od druge grupe.
                        </p><br/> 
                        <br/><h2 id="napu-tanje-ideje-o-postojanju-i-bitnosti-li-ne-vrednosti">
                            Napuštanje ideje o postojanju i bitnosti lične vrednosti
                        </h2><br/> 
                        <p>
                            Od svih pokušaja terapeuta da izvrše neku vrstu ,,dubinske" promene kod
                            klijenata samo je mali broj imao određene efekte dok su mnogi drugi imali
                            čak i negativne dugoročne efekte (barem kada su u pitanju merljivi efekti).
                            Zbog toga je jedna od velikih odluka prilikom pravljenja ovog uputstva bila
                            da se zadržimo na fiziološkim faktorima i ostalo pustimo po volji ponuđačima
                            usluge.
                        </p><br/> 
                        <p>
                            Ipak, u gomili podataka počeli su da se pojavljuju neki paterni koje nismo
                            mogli da zanemarimo, a najbitniji od svih njih je, prema našoj
                            interpretaciji, vezan za jednu vrlo specifičnu vrstu pristupa prema pitanju
                            lične vrednosti.{" "}
                        </p><br/> 
                        <p>
                            Većini terapeuta je fokus, kada je u pitanju koncept lične vrednosti, da
                            dignu ličnu vrednost svojih klijenata ili da spuste ,,ego" svojih klijenata.
                            Postoje i neke zanimljive kombinacije poput istovremenog fokusa na spuštanje
                            ,,ega'' i dizanje lične vrednosti za koje pretpostavljamo da potiču iz nekih
                            zanimljivih koncepcija koje terapeuti imaju o uzrocima tog ,,previše
                            podignutog ega, nabeđenosti ili narcisoidnosti".
                        </p><br/> 
                        <p>
                            Ali svi pokušaji ovog tipa nemaju efekte na psihološko zdravlje klijenata.
                            Ponekad utiču na napuštanje daljih terapija, ponekad povećavaju zadovoljstvo
                            klijenata terapijom, ali što se tiče izmerenih efekata, ovi pokušaji su
                            gubljenje vremena.
                        </p><br/> 
                        <p>
                            Doduše, postoji jedna grupa pokušaja rada na ličnoj vrednosti, koji kao i
                            sve ostale grupe retko daje efekte, ali kada daje efekte, efekti su veći od
                            efekata bilo koje druge grupe faktora i to veoma značajno. Zbog toga
                            smatramo da je uspeh u razumevanju i uspešnoj primeni ovog pristupa rada sa
                            ličnom vrednošću klijenata ono što u budućnosti može razlikovati dobrog od
                            elitnog ponuđača psihološkog razgovora.
                        </p><br/> 
                        <br/><h3 id="najbolji-pristup-problemu-li-ne-vrednosti">
                            Najbolji pristup problemu lične vrednosti
                        </h3><br/>
                        <p>
                            Čini se da je sama ideja postojanja lične vrednosti ono što ljude čini
                            nesrećnima zbog čega su terapeuti koji su pokušavali da razbiju realnost tog
                            koncepta u klijentovoj percepciji (umesto da menjaju klijentovu percepciju
                            svoje pozicije na toj dimenziji) ponekada samo zbog ovog pristupa ostvarili
                            veoma visoke rezultate.
                        </p><br/> 
                        <p>
                            Suština je u tome što većina ljudi oseća kako postoji neka vrsta esencijalne
                            vrednosti njih samih (i drugih) koja je nekako apsolutno bitna i na kraju
                            dana bitnija od skoro svega ostalog.{" "}
                        </p><br/> 
                        <p>
                            U toj koncepciji čovek nekako sam po sebi može biti vredan ili dobar i ljudi
                            se mogu poređati na toj nekoj skrivenoj, nevidljivoj skali na vrednije i
                            manje vredne. Ta skala jeste nevidljiva, ali onome ko pati od ovog koncepta
                            čini se kao da je svima jasna u tajnosti. Ta skala nema neki jasan
                            kriterijum.{" "}
                        </p><br/> 
                        <p>
                            To nije skala na kojoj se ljudi mere prema tome koliko su dobri teniseri,
                            koliko dobro igraju šah, koliko su lepi ili pametni. Svako ima svoje
                            kriterijume koji su nekako povezani sa tom skalom, ali na kraju dana ipak
                            postoji ta neka krajnja ideja o tome da je neko vredan a neko drugi manje
                            vredan po nekom opštem apsolutnom kriterijumu. Naravno, mnogi tvrde da su za
                            njih svi ljudi jednaki, al to je opet ništa drugo nego tvrdnja postojanja te
                            vrednosne skale na kojoj eto svi imamo jednaku vrednost.{" "}
                        </p><br/> 
                        <p>
                            Ova ideja koju mnogi intuitivno prihvataju je, čini se, samo sumanutost koja
                            otežava život klijentima i ljudima oko njih. Čovek ne može biti esencijalno
                            vredan. Neke njegove sposobnosti ili ličnosne karakteristike mogu biti
                            vredne za neke konkretne stvari, al to se ne odražava na neku apsolutnu
                            skalu svog postojanja na kojoj se svi možemo uporediti.{" "}
                        </p><br/> 
                        <p>
                            Faktori koji doprinose uspehu u narušavanje ove ideje kod klijenta jesu:
                        </p><br/> 
                        <ol>
                            <li>
                                <strong>
                                    razumevanje razlike esencijalne lične vrednosti od vrednosti za nešto
                                </strong>
                                ,
                            </li><br/>
                            <li>
                                <strong>ideološka orijentisanost</strong>,
                            </li><br/>
                            <li>
                                <strong>fantazije lične vrednosti</strong> i
                            </li><br/>
                            <li>
                                <strong>nivo bitnosti ličnovrednosne skale u okruženju</strong>.{" "}
                            </li><br/>
                        </ol><br/> 
                        <br/><h3 id="razumevanje-razlike-esencijalne-li-ne-vrednosti-od-vrednosti-za-ne-to">
                            Razumevanje razlike esencijalne lične vrednosti od vrednosti za nešto
                        </h3><br/>
                        <p>Ovo je najbolje uraditi prikazivanjem prostih primera.</p><br/> 
                        <p>
                            Ako je neko dobar u školi i misli da je zbog toga bolji ili vredniji od
                            drugih, to je suludo.
                        </p><br/> 
                        <p>
                            Ako je bolji u školi od drugih to prosto znači da je bolji u školi od većine
                            ljudi. To isto znači da će nešto verovatnije biti bolji u daljem školovanju
                            od drugih ljudi. To implicira i da bi njegov IQ skor verovatno bio viši od
                            proseka, ali nikako ne znači da je taj neko prosto bolji od drugih, šta god
                            to značilo.{" "}
                        </p><br/> 
                        <p>
                            Ovo sve važi zato što su rezultati kasnijeg školovanja statistički povezani
                            sa uspehom u ranijem školovanju. IQ test je takođe statistički povezan sa
                            uspehom u školi, ali nikada niko nije našao povezanost između uspeha u školi
                            i lične vrednosti, verovatno zato što ta lična vrednost nije neki realan
                            fenomen koji možemo dodirnuti, izmeriti i uporediti sa nečim realnim.
                        </p><br/> 
                        <p>
                            Lična vrednost je samo ljudska ideja, konstrukcija i kao mnoge druge ideje
                            iluzorna.
                        </p><br/> 
                        <p>Evo još nekih čestih primera ličnovrednosne sumanutosti. </p><br/> 
                        <ol>
                            <li>X je snažan, stoga je bolji od drugih.</li><br/>
                            <li>Y je lep, stoga je bolji od drugih.</li><br/>
                            <li>Z je siromašan, stoga je bezvredan.</li><br/>
                            <li>Pred Bogom smo svi jednaki.</li><br/>
                            <li>U komunizmu smo svi jednaki.</li><br/>
                            <li>X nije pravi Srbin.</li><br/>
                        </ol><br/> 
                        <p>
                            Prva dva primera se odnose na izjave o visokoj vrednosti nekoga na
                            ličnovrednosnoj skali dok se treći primer odnosi na izjave o niskoj
                            vrednosti na toj skali.
                        </p><br/> 
                        <p>
                            Primeri 4 i 5 pokazuju kako se ideje o jednakosti ne razlikuju od ideja o
                            nejednakosti na ovim skalama, barem kada je u pitanju psihološko zdravlje,
                            budući da se zasnivaju na iluzornom konstruktu koji narušava zdravlje onoga
                            ko taj konstrukt prihvata.
                        </p><br/> 
                        <p>
                            Primer 6 je primer indirektnog upliva ličnovrednosne skale. Ukoliko
                            izgovorimo rečenicu: ,,Pera nije pravi Srbin.", tu se uvek krije jedan
                            automatski pretpotstavljeni uslov koji kaže ,,Pravi Srbin je dobar, lažni
                            Srbin je loš". Stoga, ova prethdona izjava implicira ,,Pera je loš".
                        </p><br/> 
                        <p>
                            Prilikom objašnjavanja ovoga klijentu treba se paziti da se ne dotiču odmah
                            kriterijumi koje klijent koristi kada je u pitanju lična vrednost. Recimo,
                            ako je klijentov bitan kriterijum uspešnost u sportu, onda mu se mogu davati
                            primeri ,,štrebera" koji se naizgled bezrazložno takmiče u tome ko će imati
                            bolje ocene. Tek kada se čini da je klijent razumeo koncept, može se preći
                            na klijentove kriterijume.{" "}
                        </p><br/> 
                        <br/><h3 id="ideolo-ka-orijentisanost">Ideološka orijentisanost</h3><br/>
                        <p>
                            Veoma često se snaga ovog konstrukta krije iza radikalne privrženosti
                            određenoj ideologiji. Potencijalni razlog je to što se u skoro svakoj
                            skupini ideoloških uverenja krije vrlo direktna ideja o postojanju ovako
                            shvaćene lične vrednosti.
                        </p><br/> 
                        <p>
                            U većini monoteističkih religija se nakon smrti odlazi na Božji sud gde se
                            procenjuje ,,vrednost" čoveka, najčešće kroz kriterijum specifično shvaćene
                            moralnosti. Onda, komunizam ima jasnu sliku o tome šta je vredan čovek.
                            Slično važi i za nacizam, konfučijanizam, ,,woke kulturu", nacionalizam...{" "}
                        </p><br/> 
                        <p>
                            U ovom kontekstu mnogi drugi manji sistemi ideja mogu biti shvaćeni kao
                            ideologije. Tako bi, recimo, akademska ideologija bila ideologija u kojoj je
                            vredan onaj ko je akademski uspešan. Ideologija navijača Crvene zvezde je
                            ona u kojoj je dobar pravi navijač Crvene Zvezde, a loš ,,Grobar".
                            Ateistička ideologija je ideologija u kojoj je onaj koji veruje u Boga
                            primitivan i loš, a ateista civilizovan i dobar.
                        </p><br/> 
                        <p>
                            Bitno je shvatiti kako se ove ideologije koriste u ličnom životu klijenta.
                            Ukoliko se religija koristi za rešavanje egzistencijalnih strahova ili
                            traženja svrhe, to onda nije problematično za njegovo psihološko zdravlje
                            poput korišćenja religije zarad menjanja svoje percipirane pozicije na
                            ličnovrednosnoj skali. Isto tako ateizam može da se koristi kao prosto
                            uverenje o nepostojanju Boga ili čak dublje, kao uverenje o opasnosti
                            religije, ali bez ličnovrednosnih elemenata.
                        </p><br/> 
                        <p>
                            Napad na uverenja ovog tipa je nešto od čega će se klijenti najviše braniti
                            i zbog toga treba biti jako oprezan oko smanjivanja ideološke orijentacije.{" "}
                        </p><br/> 
                        <br/><h3 id="fantazije-li-ne-vrednosti">Fantazije lične vrednosti</h3><br/>
                        <p>
                            Ljudi često promenu percepcije svoje lične vrednosti ostvaruju kroz
                            mehanizam fantaziranja. Problem je što oni koji fantaziraju puno, posebno
                            kada su u pitanju ličnovrednosne fantazije, imaju narušeno mentalno
                            zdravlje.
                        </p><br/> 
                        <p>
                            Kod klijenata ćete naići na dve grupe ličnovrednosnih fantazija. Prva grupa
                            su <strong>fantazije veličine</strong>, druga grupa su su{" "}
                            <strong>fantazije sukoba</strong>.
                        </p><br/> 
                        <p>
                            <strong>Fantazije veličine</strong> su fantazije u kojima je osoba lik kojoj
                            se svi dive dok ona prikazuje neke izvanredne osobine i veštine.{" "}
                        </p><br/> 
                        <p>
                            <strong>Fantazije sukoba</strong> su fantazije u kojima osoba ponavlja u
                            glavi neku scenu u kojoj je osetila narušenu ličnu vrednost, menjajući
                            blago, svesno ili nesvesno, percepciju tog događaja. Tada osoba smišlja neke
                            odgovore koje nije ranije dala i zamišlja reakcije okoline i ,,suparnika" na
                            te odgovore.{" "}
                        </p><br/> 
                        <p>
                            Obe vrste fantazije narušavaju psihološko zdravlje na dugoročno, a posebno
                            intenzivno to radi <strong>fantazija sukoba</strong>. Zbog ovoga treba
                            uticati na klijenta da bude u pripravnosti svaki put kada takva fantazija
                            krene i da pokuša da je zaustavi.{" "}
                        </p><br/> 
                        <br/><h3 id="nivo-bitnosti-li-novrednosne-skale-u-okru-enju">
                            Nivo bitnosti ličnovrednosne skale u okruženju
                        </h3><br/>
                        <p>
                            Teško je ne porediti se sa drugima ukoliko se svi oko nas konstantno porede.
                            Posebno je nezgodno kada smo mi i naša ponašanja deo tuđih ličnovrednosnih
                            konstrukata. Studentu čiji se roditelji vrednuju preko njegovih uspeha na
                            fakultetu neće biti lako da se i sam ne vrednuje oko tih uspeha.{" "}
                        </p><br/> 
                        <p>
                            Zbog toga je bitno razumeti klijentovo okruženje i uticati na klijenta da se
                            udalji od odnosa u kojima je vrednovanje izraženo i da se usmeri na građenje
                            onih opuštenijih odnosa u kojima je fokus na zabavi, brizi, saznanju...{" "}
                        </p><br/> 
                        <br/><h2 id="impulsna-kontrola">Impulsna kontrola</h2><br/> 
                        <p>
                            Psihičko zdravlje je u ogromnoj vezi sa sposobnošću samodiscipline.
                            Impulsivna ponašanja, misli, odluke kojima se prepustimo gotovo uvek imaju
                            negativne posledice na psihološko zdravlje u budućnosti. Glavni faktori
                            impulsne kontrole od najbitnijih ka manje bitnim su:
                        </p><br/> 
                        <ol>
                            <li>
                                <strong>orijentacija ka realnosti</strong>,
                            </li><br/>
                            <li>
                                <strong>intelektualna skromnost i kritičko mišljenje</strong>,
                            </li><br/>
                            <li>
                                <strong>fokus i hedonizam</strong> i
                            </li><br/>
                            <li>
                                <strong>rutina i životni plan</strong>.
                            </li><br/>
                        </ol><br/> 
                        <br/><h3 id="orijentacija-ka-realnosti">Orijentacija ka realnosti</h3><br/>
                        <p>
                            Pod orijentacijom ka realnosti misli se na to koliko je pojedincu realnost
                            bitna, koliko se u životu vodi onim što smatra da je realno, koliko mu je
                            bitno da otkrije šta je realno. Čini se da od ovoga ponajviše zavisi
                            količina impulsivnosti u svakodnevnom životu. Što je orijentacija ka
                            realnosti veća, to je impulsivnost manja.
                        </p><br/> 
                        <p>Faktori orijentacije ka realnosti od najbitnijih ka manje bitnim su:</p><br/> 
                        <ol>
                            <li>
                                <strong>stavovi o bitnosti realnosti</strong>,
                            </li><br/>
                            <li>
                                <strong>potreba za učenjem van svakodnevnih zadataka</strong> i
                            </li><br/>
                            <li>
                                <strong>doslednost stavova i ponašanja</strong>.
                            </li><br/>
                        </ol><br/> 
                        <br/><h4 id="stavovi-o-bitnosti-realnosti">Stavovi o bitnosti realnosti</h4><br/>
                        <p>Izjave poput:</p><br/> 
                        <p>
                            ,,Nekada je bolje ne znati." ,,U redu je ponekada prosto verovati u nešto
                            bez razloga." ,,Ne želim to da znam, ćuti."
                        </p><br/> 
                        <p>
                            spadaju u izjave koje ukazuju na uverenje o tome da realnost nije baš
                            preterano bitna kada su u pitanju sreća ili dobar život.{" "}
                        </p><br/> 
                        <p>
                            Ipak, čini se da ljudi sa ovakvim stavovima u proseku imaju značajno
                            narušenije psihološko zdravlje zbog čega je korisno pronaći taktiku da se
                            ovakvi stavovi klijenata promene.
                        </p><br/> 
                        <br/><h4 id="potreba-za-u-enjem-van-svakodnevnih-zadataka">
                            Potreba za učenjem van svakodnevnih zadataka
                        </h4><br/>
                        <p>
                            Osoba koja, recimo, radi kao programer, ali ujedno u slobodno vreme uči
                            istoriju, najverovatnije to radi iz razloga što smatra bitnim poznavanje
                            sveta u kojem živi. Ukoliko uočite da neko radi ovakve aktivnosti, to je
                            dobar znak.{" "}
                        </p><br/> 
                        <p>
                            Ipak ukoliko ne, onda možda nije loše stvoriti ovakva ponašanja kod
                            klijenta. Najbolje je započeti sa stvarima oko kojih je klijent najviše
                            zainteresovan, pa širiti klijentova interesovanja dalje što je više moguće.{" "}
                        </p><br/> 
                        <br/><h4 id="doslednost-stavova-i-pona-anja">Doslednost stavova i ponašanja</h4><br/>
                        <p>
                            Oni koji su dosledni svojim uverenjima, ma kakva ona bila, najčešće pokazuju
                            veće stepene discipline i samim tim bolje mentalno zdravlje. Ukoliko
                            primetite da klijent često radi jedno, a priča drugo, bilo bi dobro otvoriti
                            tu temu u nekom trenutku.
                        </p><br/> 
                        <br/><h3 id="intelektualna-skromnost-i-kriti-ko-mi-ljenje">
                            Intelektualna skromnost i kritičko mišljenje
                        </h3><br/>
                        <p>
                            <strong>Kritičko mišljenje</strong> je umeće racionalne vere. Većinu naših
                            odluka o tome šta je činjenica a šta ne stičemo putem verovanja nekome ili
                            nečemu. Retko kada mi zaista istražimo činjeničnost nečega u šta verujemo.
                            To naravno nije problematično, jer kada bismo detaljno istraživali sve što
                            ikada prihvatimo kao činjenicu, ceo život bismo samo to radili.{" "}
                        </p><br/> 
                        <p>
                            Zbog toga je bitno naučiti kada, kome i čemu da verujemo. Ukoliko verujemo
                            na reč automehaničaru da automobil neće eksplodirati nakon popravke, to je
                            racionalno. Ipak, ako verujemo vraču vudu magije kako ćemo ozdraviti ako
                            udahnemo dim cveta orhideje, onda smo iracionalni.
                        </p><br/> 
                        <p>
                            Čini se da se kritičko mišljenje često gubi pre nego stiče. Oni koji
                            izjavljuju kako im realnost nije preterano bitna, najčešće imaju i slabo
                            razvijeno kritičko mišljenje. Mogući razlog je da kada neko želi da veruje u
                            sve što mu se sviđa da veruje, on brzo uguši svoje mehanizme koji ga u tome
                            sprečavaju. Vraćanje tih mehanizama kritičkog mišljenja jedan je od ključnih
                            frontova mentalnog zdravlja kod nekih klijenata.{" "}
                        </p><br/> 
                        <p>
                            Poseban problem izazvan ugušenim kritičkim mišljenjem je nekritičko
                            verovanje sebi, svojim mislima, osećajima, utiscima... Npr. hipohondar koji
                            veruje da je dobio rak štitne žlezde uprkos tome što mu svi doktori kažu
                            suprotno zapravo je osoba koja duboko veruje svojoj ličnoj intuiciji i
                            pseudologici, više nego bilo čemu drugom. Slično se može reći za većinu
                            anksioznih osoba.{" "}
                        </p><br/> 
                        <p>
                            Jedna od najbitnijih veština koju čovek može da stekne kada je u pitanju
                            mentalno zdravlje jeste da svoje misli, osećaje, intuicije i sl. stavi pod
                            iste kriterijume kao i svačije druge. Ukoliko ne verujemo intuiciji komšije
                            Pere o velikoj svetskoj zaveri Anunakija, nema razloga ni da verujemo svojoj
                            intuiciji u trenucima kada osećamo da je svet užasno mesto i da smo mi
                            potpuni neostvareni propaliteti bez budućnosti.{" "}
                        </p><br/> 
                        <br/><h3 id="fokus-i-hedonizam">Fokus i hedonizam</h3><br/>
                        <p>
                            Ono što karakteriše pojedince sa izraženom impulsivnošću je da u impulsivnim
                            ponašanjima uglavnom ne uživaju, a aktivnosti im traju kratko i brzo se
                            smenjuju. Zbog toga je bitno naučiti kako da se u svakoj aktivnosti uživa
                            koliko god je to moguće, za šta je najbitnija kontrola fokusa.{" "}
                        </p><br/> 
                        <p>
                            Ono što može biti dobra vežba sa klijentom je da se klijent izazove da u
                            pojedinim malim i prostim aktivnostima napusti sve druge stimulacije i
                            svesno se potrudi da ceo svoj fokus baci na tu aktivnost. Dobar način je za
                            početak to uraditi sa hranom, posebno kod klijenata koji nešto više uživaju
                            u hrani. Cilj je naglasiti im da dok jedu sami, ne gledaju u telefon ili
                            televizor. Onda da pokušaju da se fokusiraju na hranu i da analiziraju ukus
                            i različite karakteristike svog obroka. To dodatno mogu uraditi i sa kafom
                            ili nekom sličnom prostom rutinom koju imaju u životu.{" "}
                        </p><br/> 
                        <p>
                            Cilj je da na barem jednoj stvari nauče da hedonistično upravljaju fokusom,
                            pa kada se ta jedna stvar postigne, dobro je stalno im je ponavljati kao
                            primer i pokušavati da se na sve većem broju aktivnosti primeni isti
                            princip.
                        </p><br/> 
                        <p>
                            Uvek treba početi sa lakšim aktivnostima i aktivnostima u kojima klijenti
                            uživaju, a onda povećavati intenzitet. Potpuni uspeh u savladavanju ove
                            veštine je momenat u kojem osoba može da uživa u teškom treningu ili
                            ekstremno napornoj repetitivnoj intelektualnoj aktivnosti, mada je taj nivo
                            skoro nemoguće postići.
                        </p><br/> 
                        <p>
                            Isto, poželjno je ispitati klijentovu okolinu i vrste impulsivnosti i
                            uticati na klijenta da poveća nivo napora za ispunjavanje impulsa. Ako je
                            klijent zavistan od skrolovanja društvenih mreža, dobro je da klijent stekne
                            naviku ostavljanja telefona u drugoj sobi kada uči za ispit kao i da obriše
                            aplikacije društvenih mreža svaki put nakon korišćenja (kako bi svaki put
                            morao ponovo da ih instalira).{" "}
                        </p><br/> 
                        <br/><h3 id="rutina-i-ivotni-plan">Rutina i životni plan</h3><br/>
                        <p>
                            Odličan način da klijent bude fokusiran i disciplinovan jeste da mu je na
                            početku dana jasno šta treba da radi, kada i zašto.{" "}
                        </p><br/> 
                        <p>
                            Odlično bi bilo na seansi zajedno sa klijentom napraviti plan za dnevnu
                            rutinu, kao i nedeljni ili mesečni plan i ciljeve. Naravno, pre nego što se
                            krene sa ovim potrebno je dobro shvatiti klijenta, njegovu okolinu,
                            raspored, obaveze, novčanu situaciju, ciljeve, želje...{" "}
                        </p><br/> 
                        <p>
                            Korisno je u ovu svrhu koristiti različite digitalne tulove ili ukoliko
                            klijent preferira blokčiće u kojima se može jasno nacrtati, skicirati plan.{" "}
                        </p><br/> 
                        <p>
                            U početku treba kreirati nešto labavije rutine sa više zabavnih aktivnosti i
                            manje preciznih satnica, ali onda kako klijent bude sve uspešniji u
                            postizanju dnevnih i nedeljnih ciljeva, plan se treba otežavati i postajati
                            sve ispunjeniji korisnim aktivnostima.
                        </p><br/> 
                        <p>
                            Ukoliko je moguće u plan treba inkorporirati što više elemenata faktora
                            fiziološkog stanja.
                        </p><br/> 
                       <br/><h1  id="primena-uputstva">Primena uputstva</h1><br/><br/> 
                        <p>
                            Nakon što se izvuku prethodni faktori, njihova značenja i povezanosti i
                            kreira opšti plan primene u razgovorima, može se početi sa razgovorima.
                            Tokom razgovora potrebno je koristiti <b>EVA beležnicu</b>. U daljem tekstu
                            ćemo opisati korišćenje beležnice.
                        </p><br/> 
                       <br/><h1  id="eva-bele-nica-1-0">Eva beležnica 1.0</h1><br/><br/> 
                        <img className="notesImage" src={notesImage} alt="Beležnica" /><br /> <br />
                        <p>
                            Beležnica je napravljena u <b>Google Sheet</b>-u. Svaki list (sheet) beležnice
                            se koristi za jednog klijenta. Dakle, ukoliko imate 5 klijenata, za rad vam
                            je potrebno 5 listova.
                        </p><br/> 
                        <p>
                            U redovima se nalaze faktori (iz prethodnog teksta), u kolonama redosled
                            seansi. Broj seansi na jednom listu se može manuelno povećati iznad broja od
                            12 seansi što je početni broj na jednom listu.
                        </p><br/> 
                        <p>
                            Beležnica se popunjava tokom ili posle seanse. Popunjava se ona kolona koja
                            označava trenutnu ili prethodnu seansu.{" "}
                        </p><br/> 
                        <p>
                            Svaka ćelija je kombinacija redosleda seanse i određenog faktora. U svaku
                            ćeliju se piše procenjena vrednost klijentovog stanja na faktoru koji
                            odgovara redu u kome se ćelija nalazi u trenutku seanse x. Npr. ako popunimo
                            ćeliju F4 sa slike sa vrednošću 8h to znači da je prosečna dnevna količina
                            sna klijenta tokom nedelje u kojoj je izvršena peta seansa bila 8 sati.{" "}
                        </p><br/> 
                        <br/><h2 id="kako-se-bele-nica-popunjava-">Kako se beležnica popunjava?</h2><br/> 
                        <p>
                            Nakon svake seanse sa klijentom popunjava se ćelija sa vrednošću specifičnom
                            za faktor u koloni koja predstavlja broj prethodne seanse.{" "}
                        </p><br/> 
                        <ul>
                            <li>
                                Ukoliko mislite da nemate dovoljno informacija za procenu vrednosti u
                                ćeliji, ćeliju popunite sa kosom crtom (/). Kosa crta će očigledno biti u
                                najvećem broju ćelija tokom prvih par seansi.
                            </li><br/>
                            <li>
                                Ukoliko tokom trenutne seanse niste dobili dodatne informacije koje bi
                                promenile procenu u odnosu na prethodnu seansu, onda prepišite vrednost iz
                                prethodne seanse za taj faktor. Npr. ukoliko je količina sna za prethodnu
                                seansu bila 8h, onda pišete 8h za trenutnu seansu.{" "}
                            </li><br/>
                        </ul>
                        <p>
                            Ostalo je da još da napišemo koje se vrednosti pišu u ćelije specifične za
                            svaki pojedinačni faktor.
                        </p><br/> 
                        <br/><h2 id="specifi-ne-vrednosti-za-faktore">Specifične vrednosti za faktore</h2><br/> 
                        <p>
                            <em>Specifične vrednosti se unose za 7 dana koji prethode seansi.</em>
                        </p><br/> 
                        <ul>
                            <li>
                                <p>
                                    <strong>Prosečna količina sna</strong>: Unosi se procenjeno prosečno
                                    vreme sna, u satima ili minutima (pr. <strong>6h 30min</strong>.).{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Poslednji kofein (vreme)</strong>: Unosi se procenjeni prosečan
                                    broj sati i minuta između poslednjeg unosa kofeina i odlaska u krevet
                                    (pr. <strong>2h</strong>).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Poslednji nikotin (vreme)</strong>: Unosi se procenjeni prosečan
                                    broj sati i minuta između poslednjeg unosa nikotina i odlaska u krevet
                                    (pr. <strong>30 min.</strong>).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Poslednji obrok (vreme)</strong>: Unosi se procenjeni prosečan
                                    broj sati i minuta između poslednjeg obroka i odlaska u krevet (pr.{" "}
                                    <strong>3h 30 min.</strong>).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Poslednji obrok (količina)</strong>: Unosi se ocena od 1 do 10,
                                    gde 1 predstavlja kalorijski nizak obrok (poput jedne do dve jabuke) dok
                                    10 predstavlja obrok u kome se klijent zaustavio zbog toga što bi se
                                    ispovraćao da je uzeo još zalogaj.{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Stres i anksioznost pre i tokom uspavljivanja</strong>: Unosi se
                                    ocena od 1 do 10, gde 1 predstavlja nepostojanje stresa i anksioznosti
                                    pre i tokom uspavljivanja dok 10 predstavlja toliko visok stepen stresa
                                    i anksioznosti da klijent zbog toga uopšte nije mogao da spava.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Varijacija odlaska u krevet</strong>: Unosi se ocena od 1 do 10,
                                    gde 1 predstavlja konstantnost satnice odlaska u krevet i buđenja dok 10
                                    predstavlja svakodnevnu promenu satnice odlaska u krevet i buđenja.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Buka tokom spavanja</strong>: Unosi se ocena od 1 do 10, gde 1
                                    predstavlja nepostojanje buke koja bi mogla dopreti do klijentovog mesta
                                    spavanja dok 10 predstavlja zvuk jačine poput zvuka bušilice u sobi
                                    pored.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Svetlost tokom spavanja</strong>: Unosi se ocena od 1 do 10, gde
                                    1 predstavlja potpuni mrak na klijentovom mestu spavanja dok 10
                                    predstavlja upaljeno svetlo na mestu spavanja.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Vreme odlaska u krevet</strong>: Unosi se procenjeno prosečno
                                    vreme odlaska u krevet (pr. <strong>23h 30min</strong>.).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Temperatura tokom spavanja</strong>: Unosi se procenjena
                                    prosečna temperatura prostorije za spavanje, u stepenima celzijusa (pr.{" "}
                                    <strong>15°C</strong>).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Misaona aktivnost pre uspavljivanja</strong>: Unosi se ocena od
                                    1 do 10, gde 1 predstavlja nepostojanje misaone aktivnosti pre
                                    uspavljivanja dok 10 predstavlja toliko snažnu misaonu aktivnost da je
                                    klijentu gotovo nemoguće da je ,,isključi''.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Minuti bez ekrana pre uspavljivanja</strong>: Unosi se
                                    procenjeno prosečno vreme nekorišćenja telefona pre uspavljivanja, u
                                    minutima ili satima (pr. <strong>30min</strong>.).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Konstantnost fizičke aktivnosti</strong>: Unosi se broj od 1 do
                                    7, gde svaki broj predstavlja broj dana kada je klijent bio fizički
                                    aktivan (pr. <strong>2</strong>{" "}
                                    <em>
                                        što znači da je klijent imao fizičku aktivnost 2 dana tokom 7 dana
                                        koji prethode seansi
                                    </em>
                                    ).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Trajanje fizičke aktivnosti</strong>: Unosi se procenjeno
                                    prosečno vreme trajanja fizičke aktivnosti, u satima ili minutima (pr.{" "}
                                    <strong>45min</strong>.).{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Intenzitet fizičke aktivnosti</strong>: Unosi se procenjeni
                                    prosečni broj puta koliko se klijent zadihao za vreme trajanja fizičke
                                    aktivnosti (tako da mu je pričanje bilo znatno otežano), (pr.{" "}
                                    <strong>2</strong>).{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Zabava tokom fizičke aktivnosti</strong>: Unosi se ocena od 1 do
                                    10, gde 1 predstavlja ekstremnu dosadu dok 10 predstavlja
                                    ,,jedvačekanje'' odlaska na trening.{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Kofein</strong>: Unosi se procenjeni prosečni unos kofeina, u
                                    miligramima.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Nikotin</strong>: Unosi se procenjeni broj popušenih pakovanja
                                    cigareta.{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Alkohol</strong>: Unosi se procenjeni broj konzumiranih pića
                                    (jedno piće je ekvivalentno jednoj čaši vina, limenki piva, čašici
                                    rakije i sl.).
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Ilegalne lake droge</strong>: Unosi se broj od 1 do 7, gde svaki
                                    broj predstavlja broj dana kada je klijent konzumirao ilegalne lake
                                    droge.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Ilegalne teške droge</strong>: Unosi se broj od 1 do 7, gde
                                    svaki broj predstavlja broj dana kada je klijent konzumirao ilegalne
                                    teške droge.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Psihijatrijski lekovi</strong>: Unosi se broj od 1 do 7, gde
                                    svaki broj predstavlja broj dana kada je klijent konzumirao
                                    psihijatrijske lekove.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Procenjeni kvalitet ishrane</strong>: Unosi se ocena od 1 do 10,
                                    gde 1 predstavlja izuzetno nizak kvalitet ishrane dok 10 predstavlja
                                    izuzetno visok kvalitet ishrane.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Procenjeni kvalitet okruženja za zdrave navike</strong>: Unosi
                                    se ocena od 1 do 10, gde 1 predstavlja izuzetno nizak kvalitet okruženja
                                    za zdrave navike dok 10 predstavlja izuzetno visok kvalitet okruženja za
                                    zdrave navike.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Nivo razumevanja koncepta lične vrednosti</strong>: Unosi se
                                    ocena od 1 do 10, gde 1 predstavlja potpuno nerazumevanje ovog koncepta
                                    dok 10 predstavlja potpuno razumevanje koncepta. Razumevanje je najbolje
                                    proveriti kroz primere i na način dat u ovom uputstvu.{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Nivo ideološke orijentisanosti</strong>: Unosi se ocena od 1 do
                                    10, gde 1 predstavlja nepostojanje privrženosti bilo kojoj ideologiji
                                    dok 10 predstavlja potpunu opsednutost nekom ideološkom idejom.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Količina fantazija veličine</strong>: Unosi se broj od 1 do 7,
                                    gde svaki broj predstavlja broj dana kada je klijent imao fantaziju
                                    veličine.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Količina fantazija sukoba</strong>: Unosi se broj od 1 do 7, gde
                                    svaki broj predstavlja broj dana kada je klijent imao fantaziju sukoba.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Snaga lične vrednosti u okruženju</strong>: Unosi se ocena od 1
                                    do 10, gde 1 predstavlja nepostojanje ličnog vrednovanja u okruženju
                                    klijenta dok 10 predstavlja izuzetno visok nivo zastupljenosti ličnog
                                    vrednovanja (primeri visokog vrednovanja su ultra konzervativna
                                    porodica, društvo koje liči na rijaliti i sl.).{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Intenzitet uverenja o bitnosti realnosti</strong>: Unosi se
                                    ocena od 1 do 10, gde 1 predstavlja klijentovo uverenje o potpunoj
                                    nebitnosti realnosti dok 10 predstavlja klijentovo izuzetno snažno
                                    uverenje o bitnosti realnosti.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Intenzitet potrebe za učenjem van svakodnevnih zadataka</strong>
                                    : Unosi se ocena od 1 do 10, gde 1 predstavlja nepostojanje potrebe za
                                    učenjem van svakodnevnih obaveza dok 10 predstavlja izuzetno visoku
                                    potrebu za učenjem van svakodnevnih aktivnosti.{" "}
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Stepen doslednosti stavova i ponašanja</strong>: Unosi se ocena
                                    od 1 do 10, gde 1 predstavlja potpunu nedoslednost stavova i ponašanja
                                    dok 10 predstavlja potpunu doslednost stavova i ponašanja.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Sposobnost kritičkog mišljenja</strong>: Unosi se ocena od 1 do
                                    10, gde 1 predstavlja nepostojanje kritičkog mišljenja dok 10
                                    predstavlja izuzetno visoku sposobnost kritičkog mišljenja.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Nivo intelektualne skromnosti</strong>: Unosi se ocena od 1 do
                                    10, gde 1 predstavlja izuzetno nizak nivo intelektualne skromnosti dok
                                    10 predstavlja izuzetno visok nivo intelektualne skromnosti.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Nivo fokusiranosti na aktivnosti</strong>: Unosi se ocena od 1
                                    do 10, gde 1 predstavlja potpunu nemogućnost fokusiranosti na aktivnosti
                                    dok 10 predstavlja potpunu fokusiranost i česta ,,flow" stanja.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Količina hedonizma tokom aktivnosti</strong>: Unosi se ocena od
                                    1 do 10, gde 1 predstavlja nepostojanje hedonizma tokom aktivnosti dok
                                    10 predstavlja izuzetno veliku količinu hedonizma tokom skoro svih vrsta
                                    aktivnosti.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Rutiniziranost</strong>: Unosi se ocena od 1 do 10, gde 1
                                    predstavlja nepostojanje rutine u klijentovoj svakodnevici dok 10
                                    predstavlja potpunu rutiniziranost.
                                </p><br/> 
                            </li><br/>
                            <li>
                                <p>
                                    <strong>Određenost životnog plana</strong>: Unosi se ocena od 1 do 10,
                                    gde 1 predstavlja nepostojanje životnog plana dok 10 predstavlja potpunu
                                    usmerenost i određenost životnog plana.
                                </p><br/> 
                            </li><br/>
                        </ul>
                    </>

                </div> :
            null}    
        <Footer />    
    </div>
)
}

export default Manual