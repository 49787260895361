import RingLoader from "react-spinners/RingLoader";
import React, {useState,useEffect,useRef} from 'react';
import Header from "../Header/Header";
import { doc,updateDoc, getDoc, setDoc, arrayUnion, getDocs, where, collection,query,deleteDoc } from "firebase/firestore";
import { logEvent,setUserId,setUserProperties} from "firebase/analytics";
import { db, analytics} from "../../config/firebase-config";
import "./Root.css";
import Card from '../Card/Card';
import { ReactComponent as MGlass } from "../../SVGs/MGlass.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, app} from "../../config/firebase-config";
import { useStateValue } from "../StateProvider";
import { getMessaging, getToken, onMessage } from 'firebase/messaging'; 
import Joyride, { CallBackProps, STATUS, ACTIONS } from 'react-joyride';
import SpeechBubble from "../SpeechBubble/SpeechBubble";
import { ReactComponent as Avatar } from '../../SVGs/Avatar.svg';
import useWindowWidth from '../WindowsWidthHook';
import RequestPermissionButton from '../RequestPermissionButton/RequestPermissionButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VideoPopUp from '../PopUps/VideoPopUp/VideoPopUp';
import { signOut } from "firebase/auth"; 
import Footer from "../Footer/Footer";
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import getFunctions and httpsCallable





const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
let myNotification = !('Notification' in window) ? {} : Notification;


const Root =  () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [isMoreAvailable, setIsMoreAvailable] = useState(true);
    const [datas, setData] = useState([]);
    let   [search, setSearch] = useState();
    let   [type, setType] = useState('no-type');
    const [{basket},dispatch] = useStateValue();
    const [run, setRun] = useState(false);
    const [run2,setRun2] = useState(false);
    const [run3,setRun3] = useState(false);
    const width = useWindowWidth();
    const [windowIsWide, setWindowIsWide] = useState(width > 670)
    const listsRef = useRef(null);
  const selectRef = useRef(null);
  const [loadingFeed, setLoadingFeed] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [avatarSeenThisSesion, setAvatarSeenThisSesion] = useState(false);
  const [avatarSeenThisSesion2, setAvatarSeenThisSesion2] = useState(false);
  const [avatarSeenThisSesion3, setAvatarSeenThisSesion3] = useState(false);
  
  const functions = getFunctions(app);
  const sendEmail = httpsCallable(functions, 'sendEmail');

  const checkApprovement = async() => {
  if (auth.currentUser && basket[0].length !== 0 && basket[0].userType !== 'client' && !basket[0].approved) {
    alert('Vaša prijava je u proveri, informacije o potrebnim podacima koje treba da dostavite će Vam biti poslati na mejl u roku od 24h');
    await signOut(auth);
  }
  }
  
  useEffect(() => {
    /* alert(auth.currentUser.uid) */
    const nowDate = new Date();
    if (basket && basket[0]?.email === 'npejdic@gmail.com') {
      const fetchFutureEmails = async () => {
        try {
          // Construct the query
          const emailsRef = collection(db, 'Emails');
          const q = query(emailsRef, where('date', '<', nowDate));

          // Execute the query and process the results
          const querySnapshot = await getDocs(q);

          for (const doc of querySnapshot.docs) {
            const docData = doc.data();

            // First email data
            const emailData2 = {
              to: docData.email1,
              subject: 'Upitnik o prethodnoj sesiji',
              messageBody: `
              <b>Popunjavanje upitnika traje u proseku 3 minuta.</b>
              <br><br>
              <b>Link:</b> <a href="https://forms.gle/heETGRPMsy9kHutw6">Google forms link</a>
              <br><br>
              <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
            `
            };

            // Second email data
            const emailData = {
              to: docData.email2,
              subject: 'Upitnik o prethodnoj sesiji',
              messageBody: `
              <b>Popunjavanje upitnika traje u proseku 3 minuta.</b>
              <br><br>
              <b>Link:</b> <a href="https://forms.gle/heETGRPMsy9kHutw6">Google forms link</a>
              <br><br>
              <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
            `
            };

            let email1Sent = false;
            let email2Sent = false;

            // Send first email
            try {
              const result = await sendEmail(emailData2);
              console.log('Email sent successfully to email1:', result.data);
              email1Sent = true;
            } catch (error) {
              console.error("Error sending email to email1:", error);
            }

            // Send second email
            try {
              const result = await sendEmail(emailData);
              console.log('Email sent successfully to email2:', result.data);
              email2Sent = true;
            } catch (error) {
              console.error("Error sending email to email2:", error);
            }

            // If both emails were sent successfully, delete the document
            if (email1Sent || email2Sent) {
              try {
                await deleteDoc(doc.ref);
                console.log('Document deleted successfully');
              } catch (error) {
                console.error('Error deleting document:', error);
              }
            } else {
              console.log('Emails not sent successfully, document not deleted');
            }
          }
        } catch (error) {
          console.error('Error fetching future emails:', error);
        }
      };

      fetchFutureEmails();
    }
  }, []);



  useEffect(() => {
    if (auth.currentUser && basket && basket[0]?.analytics_user !== true) {
    setUserId(analytics, auth.currentUser.uid);
    setUserProperties(analytics, {
      user_type: basket[0].userType
    })
      const clientDocRef = doc(db, 'Clients', auth.currentUser?.uid);
      setDoc(clientDocRef, { analytics_user: true }, { merge: true });
    }
  }, [])
  
  useEffect(() => {
    if (auth.currentUser && basket && basket[0]?.averageRating && basket[0]?.numberOfRatings) {
    setUserProperties(analytics, {
      average_rating: basket[0]?.averageRating,
      number_of_ratings: basket[0]?.numberOfRatings
    })
    }
  },[])
  
  useEffect(() => {
    checkApprovement();
  }, [])
  
  useEffect(() => {
    if (auth.currentUser && !basket[0]?.privacyPoliceAccepted) {
      navigate('/privacy-policy')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite politiku privatnosti.')

    } 
    
    if (auth.currentUser && basket[0]?.privacyPoliceAccepted && !basket[0]?.termsAndConditionAccepted) {
      navigate('/terms-and-conditions')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite uslove korišćenja.')
    }
  }, [])
  
  
  

    const [isPopUpVideoOpen, setIsPopUpVideoOpen] = useState(false);
    const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/evaapp-46d63.appspot.com/o/Instrukcije%20za%20ajfon.mp4?alt=media&token=e06bb3f1-019d-4895-8a7d-ba24dd45813e';
    const openVideoPopup = () => setIsPopUpVideoOpen(true);
  const closeVideoPopup = () => setIsPopUpVideoOpen(false);
  
  useEffect(() => {
    if (auth.currentUser && basket[0]?.len < 5) {
      setLoading(true);
    } else {
      setLoading(false);
    } 
  },[auth.currentUser, basket[0]])

    const handleAvatarNotificationButtonClick = () => {
  
    
      if (isIOS && !isInStandaloneMode && !avatarSeenThisSesion3)
      {
        setRun3(true);
        setAvatarSeenThisSesion3(true);
        setRun2(false);
        logEvent(analytics, 'open_video_avatar', {
          tutorial_location: 'Feed2',
          mode: isInStandaloneMode ? 'standalone' : 'browser',
        });
      }else{
        logEvent(analytics, 'allow_notification', {
          tutorial_location: 'Feed2',
          mode: isInStandaloneMode ? 'standalone' : 'browser',
        });
        Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          // With permission, now get messaging token
          const messaging = getMessaging();
          getToken(messaging, { vapidKey: 'BIUi3uPzzl-s6RnTjQR-KlYUiNHP0o3JfoFZvqZlKxGq_gK2dcjo6SOtHH8AsqZluiFEN6oopH6so08olnvIIEQ' })
            .then((currentToken) => {
              if (currentToken) {
                // Save or update the token in your database
                const clientDocRef = doc(db, 'Clients', auth.currentUser?.uid);
                setDoc(clientDocRef, { token: arrayUnion(currentToken) }, { merge: true });
              } else {
                console.log('No registration token available. Make sure notifications permissions are granted.');
              }
            }).catch((error) => {
              console.error('Error getting registration token:', error);
            });
        } else {
          console.log('Notification permission not granted.');
        }
      });}
    }


    useEffect(()=>{
      setWindowIsWide(width>670)
    },[width])
    
  // Steps for your tour
  const [steps, setSteps] = useState([
    
    {
      target: '.my-first-step',
      content: 
      <div className="AvatarContainer">
        <Avatar/>
        <SpeechBubble >
    <p>
      Dobrodošao/la na EVU! 
      <br/>
      <br/>
      Ovde možeš pronaći najbolju psihološku pomoć nezavisno od svog budžeta.
      <br/>
      Danas ću biti tvoj asistent i provešću te kroz aplikaciju.
    </p>
  
</SpeechBubble>
      </div>,
      disableBeacon:true,
      placement: "center",
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              position: "relative",
              
            },
            
            
          }
    },
    {
      target: windowIsWide ? '.Lists' : ".step2",
      content: 
      <div className="AvatarContainer" style={{position:"relative", top:"3rem"}}>
        <Avatar/>
        <SpeechBubble style={{position:"relative", bottom:"5rem", left:"-1rem"}}>
          <p>
            Ovde možeš izabrati jednu od 3 kategorije ponuđača.
            <br/>
            Kategorije se razlikuju u odnosu na znanje i nivo obrazovanja ponuđača.
          </p>
        </SpeechBubble>
      </div>,
      
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              offset: 100
            },
           
            buttonBack: {
              color: 'black', // This will apply the color black to the text of the back button
            },
            
          }
    },
    {
      target: ".LogOut",
      content: 
      <div className="AvatarContainer" style={{position:"relative", top:"3rem"}}>
        <Avatar/>
        <SpeechBubble style={{position:"relative", bottom:"5rem", left:"-1rem"}}>
          <p>
            Ukoliko želiš da zakažeš termin kod nekog ponuđača, potrebno je da se prvo registruješ.
          </p>
        </SpeechBubble>
      </div>,
      
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              offset: 100
            },
            buttonBack: {
              color: 'black', // This will apply the color black to the text of the back button
            },   
          }
    },
  ]);


  const [steps2, setSteps2] = useState([
        {
      target: '.my-first-step',
      content: 
      <div className="AvatarContainer">
        <Avatar/>
        <SpeechBubble>
    {basket[0]?.userType === 'client' ? 
      
    <p >Uspešno si se registrovao/la! 
      <br/>
      <br/>

      Pomoći ću ti da zakažeš svoju prvu seansu.
      <br/>
      Nakon što izabereš ponuđača iz bilo koje od 3 kategorije, prebacićemo se na profil ponuđača kako bi video/la dodatne informacije o njemu/njoj.
    </p> 
    : 
    <p>
    Iako si u ulozi ponuđača psihološke podrške, to ne znači da ne možeš sebi da priuštiš psihološku podršku ako ti je potrebna. 
    <br/>
    Ukoliko želiš, uvek možeš odabrati nekog od ponuđača iz jedne od 3 kategorije nakon čega odlazimo na njegov/njen profil na kom možeš zakazati termin.
  </p>
    }
  
</SpeechBubble>
      </div>,
      disableBeacon:true,
      placement: "center",
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width:"min(30rem, 90vw,90vh)",
              zIndex: 100, 
            },
        }
    },{
      target: ".Profile",
      content: 
      <div className="AvatarContainer" style={{position:"relative", top:"3rem"}}>
        <Avatar/>
        <SpeechBubble style={{position:"relative", bottom:"5rem", left:"-1rem"}}>
          {basket[0]?.userType === 'client' ? 
          <p>
            Klikom ovde možeš u bilo kom trenutku podesiti informacije na svom profilu ukoliko želiš.
          </p>
          :
          <p>
            Klikom ovde odlaziš na svoj profil gde možeš ostaviti informacije o sebi i podesiti svoje termine. Nakon tog procesa bićeš u mogućnosti da staviš svoju ponudu na feed.
          </p>
          }
        </SpeechBubble>
      </div>,
      
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              offset: 100
            },
           
            buttonBack: {
              color: 'black', // This will apply the color black to the text of the back button
            },
            
          }
    },
    /* {
      target: myNotification?.permission !== 'granted' ? '.my-first-step' : 'asd',
      content: (
        <div className="AvatarContainer">
          <Avatar />
          <SpeechBubble style={myNotification?.permission === 'granted'? {position:"relative", top:'0.01rem'}: {}}>
              <p>
                Ukoliko želiš da dobiješ obaveštenja o novim terminima i porukama od drugih korisnika, klikni na dugme ispod.
                <ArrowDownwardIcon className='ArrowDownwardIcon' style={{fontSize:"1.2rem", position:"relative", top:'0.2rem'}} />
                <br/>
                <button
                  style={{
                    display: "flex",
                    color: "white",
                    backgroundColor: "hsla(174, 40%, 44%, 1)",
                    padding: "3% 4%",
                    borderRadius: "2vh",
                    transition: "all .3s ease",
                    cursor:"pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1rem",
                    borderWidth: "0.05vh",
                    fontWeight: "500",
                    width:"100%",
                    marginTop: "2vh",
                  }}
                  onClick={handleAvatarNotificationButtonClick}
                  aria-label="Dozvoli obaveštenja"
                >
                  Dozvoli obaveštenja
                </button>
              </p>
          </SpeechBubble>
        </div>
      ),
      disableBeacon:true,
      placement: "center",
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              
            },
            
            
          }
    }, */

  ]);


  const [steps3, setSteps3] = useState([
    
    {
      target: '.my-first-step',
      content:
      <> 
      <div className="AvatarContainer">
        <Avatar/>
        <SpeechBubble >
    <p>
      S obzirom da koristiš iPhone, potrebno je da aplikaciju skineš na telefon kako bi mogao/la da primaš notifikacije.
      <br/>
      U kratom video tutorijalu ispod možeš videti kako da to uradiš u 3 klika.
    </p>
  
</SpeechBubble>


      </div>
      <img onClick={openVideoPopup} className="addToHomeInstructionVideo"
      src='/play-button-6694069_1280.png'
      >
        
      </img>
     </>,
      disableBeacon:true,
      placement: "center",
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              position: "relative",
              
            },      
          }
    },
    ]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    if (index === 0 && type === 'step:before') {
      // Log 'tutorial_begin' event
      logEvent(analytics, 'tutorial_begin', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }
  
    // Tutorial Complete
    if (status === 'finished') {
      // Log 'tutorial_complete' event
      logEvent(analytics, 'tutorial_complete', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }
  
    // Tutorial Closed
    if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
      // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
      logEvent(analytics, 'feed_tutorial_closed', {
        step_index: index + 1, // Indicates the last step viewed before closing
        reason: status, // 'skipped' or 'closed'
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    } 

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      setRun(false); // Stops the tour
    }
    // You can add more conditions here based on your requirements.
  };
  const handleJoyrideCallback2 = (data) => {
    const { action, index, type, status } = data;

    if (index === 0 && type === 'step:before') {
      // Log 'tutorial_begin' event
      logEvent(analytics, 'tutorial_begin', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }
  
    // Tutorial Complete
    if (status === 'finished') {
      // Log 'tutorial_complete' event
      logEvent(analytics, 'tutorial_complete', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
      
    }
  
    // Tutorial Closed
    if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
      // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
      logEvent(analytics, 'feed_tutorial_closed', {
        step_index: index + 1, // Indicates the last step viewed before closing
        reason: status, // 'skipped' or 'closed'
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
     
    } 

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      setRun3(false); // Stops the tour
      setRun2(true);
    }
    // You can add more conditions here based on your requirements.
  };
  
    useEffect(()=>{
      if (basket && basket.length > 0 && basket[0].hasOwnProperty("approved") && basket[0].approved === true ) {
        if (!basket[0].feedAvatarSeen) {
          setRun2(true);
          dispatch({
            type: 'UPDATE_BASKET_PROPERTY',
            property: 'feedAvatarSeen', // The name of the property you want to update
            value: true // The new value for the property
          });
        try {
          const userRef = doc(db, "Clients", auth.currentUser.uid);
          const updateAvatar = async()=>{
            await updateDoc(userRef, {
          feedAvatarSeen: true
          }
          );}
          updateAvatar();
        }
          catch(e){
            console.log(e)
          }}}
      else if (!auth.currentUser && !basket[0]?.welcomeAvatarSeen ) {
        setRun(true);
        dispatch({
          type: "ADD_TO_BASKET",
          item: {
            welcomeAvatarSeen:true  
          }
        });

      } else if (basket[0]?.userType === 'client'){
      if(!basket[0].feedAvatarSeen){
        setRun2(true);
        dispatch({
          type: 'UPDATE_BASKET_PROPERTY',
          property: 'feedAvatarSeen', // The name of the property you want to update
          value: true // The new value for the property
        });
        setAvatarSeenThisSesion2(true);
        try {
          const userRef = doc(db, "Clients", auth.currentUser.uid);
          const updateAvatar = async()=>{
            await updateDoc(userRef, {
          feedAvatarSeen: true
          }
          );}
          updateAvatar();
        }
          catch(e){
            console.log(e)
          }}
     }
     /*  else if(auth.currentUser) {
       navigate(`/login`)
       alert('Hvala na prijavi, trenutno proveravamo Vaše podatke');
       } */else {
       }
   },[])

   
  
    useEffect(() => {
      presentLists();
    }, [type]);  

    
    useEffect(() => {
      if ('serviceWorker' in navigator) {
        const messaging = getMessaging();
      
        // Get the token for the device
        getToken(messaging, { vapidKey: 'BIUi3uPzzl-s6RnTjQR-KlYUiNHP0o3JfoFZvqZlKxGq_gK2dcjo6SOtHH8AsqZluiFEN6oopH6so08olnvIIEQ' })
            .then((currentToken) => {
                if (currentToken) {
                    // Save or update the token in your database
                    if (auth.currentUser){
                    const clientDocRef = doc(db, 'Clients', auth.currentUser?.uid);
                    setDoc(clientDocRef, { token: arrayUnion(currentToken) }, { merge: true });
                    }
                } else {
                    console.log('No registration token available. Make sure notifications permissions are granted.');
                }
            }).catch((error) => {
                console.error('Error getting registration token:', error);
            });
        // Listen to messages when the app is in the foreground
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // Process your message as required
        });
      } else {
        console.log('Service Workers are not supported in this environment or Notification permission has not been granted. Firebase messaging will not be initialized.');
      }
    }, []);
  
  
    
  const getDocumentName = (type) => {
    if (type === 'razgovor') {
      return 'ListOfIds1';
    } else if (type === 'savetnik') {
      return 'ListOfIds2';
    } else if (type === 'psychotherapy') {
      return 'ListOfIds3';
    } else if (type === 'no-type') {
      return 'ListOfIdsAll';
    } else if (type.includes('search')) {
      return 'ListOfIdsAll';
    } else {
      return null; // Default case
    }
  };

  const presentLists = async () => {
    setLoadingFeed(true);
      try {
        
        const listType = getDocumentName(type);
        const listDocRef = doc(db, 'ClientsIds', listType);
        const listDocSnap = await getDoc(listDocRef);
    
        if (!listDocSnap.exists()) {
          console.log("No such document!");
          setIsMoreAvailable(false);
          return;
        }
    
        const idsObj = listDocSnap.data().ids;
        const filteredIds = Object.entries(idsObj)
          .filter(([key, value]) => {
            // Existing filters
            const onHomeCheck = value.onHome === 1;

            // Safely access workingTime
            const workingTimesExist =
              value.workingDays &&
              value.workingDays.workingTime &&
              value.workingDays.workingTime.length !== 0;

            const noFutureDates =
              !value.workingDays ||
              !value.workingDays.specificDates ||
              value.workingDays.specificDates.every((dateString) => {
                const date = new Date(dateString);
                return date < new Date();
              });

            // Initial pass based on existing filters
            let passesFilters = onHomeCheck && (!noFutureDates || workingTimesExist);

            // Additional filtering if 'type' includes 'search'
            if (type.includes('search') && search.length > 0) {
              // Split 'search' string into words
              const searchWords = search
                .toLowerCase()
                .split(' ')
                .filter((word) => word); // Remove empty strings

              // Get 'name' and 'surname' from 'value', ensuring they are strings
              const name = (value.name || '').toLowerCase();
              const surname = (value.surname || '').toLowerCase();

              // Check if any search word is included in 'name' or 'surname'
              const matchesSearch = searchWords.some(
                (word) => name.includes(word) || surname.includes(word)
              );

              // Update 'passesFilters' to include 'matchesSearch'
              passesFilters = passesFilters && matchesSearch;
            }

            return passesFilters;
          })
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});


    
        // Create a weighted array
        let weightedArray = [];
        for (const [key, value] of Object.entries(filteredIds)) {
          let weight = 1; // Default weight
          if (value.averageRating && !isNaN(value.averageRating)) {
            weight = Math.max(value.averageRating, 1);
          }
    
          for (let i = 0; i < weight; i++) {
            weightedArray.push(key);
          }
        }
    
        // Shuffle the weighted array
        for (let i = weightedArray.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [weightedArray[i], weightedArray[j]] = [weightedArray[j], weightedArray[i]];
        }
    
        // Select unique keys
        let selectedKeys = new Set();
        while (selectedKeys.size < 5 && weightedArray.length > 0) {
          let randomIndex = Math.floor(Math.random() * weightedArray.length);
          selectedKeys.add(weightedArray.splice(randomIndex, 1)[0]);
        }
    
        // Fetch selected users
        const fetchedUsers = await Promise.all([...selectedKeys].map(key => 
          getDoc(doc(db, 'Clients', key)).then(docSnap => ({ id: key, ...docSnap.data() }))
    ,    ));
    
        setData(fetchedUsers);
        setIsMoreAvailable(fetchedUsers.length >= 5);
      } catch (error) {
        console.error("Error getting documents: ", error);
    } 
    setLoadingFeed(false);
    };
    
    
    const onClickCard = (navName,price) => {
      if (auth.currentUser) {
          logEvent(analytics, 'select_therapist', {
            receiver_name: navName,
            receiver_type: type,
            price: price,          
        });
        navigate(`profile/${navName}`);

      }
      else{
      logEvent(analytics, 'select_therapist', {
        receiver_name: navName,
        receiver_type: type,
        price: price,
    });
      navigate(`profile/${navName}`)
      }
    } 

    function showCards(){
      return datas.map((data)=>{
        return (
          <Card 
            key={`${data.name}.${data.surname}`} 
            price={data.price} 
            search={search} 
            setSearch={setSearch} 
            imageURL={data.imageURL} 
            description={data.description} 
            rating={data.averageRating}
            spec1={data.spec1} 
            spec2={data.spec2} 
            spec3={data.spec3} 
            name={data.name} 
            surname={data.surname} 
            onClick={onClickCard}  
          />
        )
      })
    }

  const loadMore = async () => {
      setIsLoadMore(true)
      logEvent(analytics,'click_load_more_feed')
      if (!isMoreAvailable) return;
    
      try {
        
        const listType = getDocumentName(type);
        const listDocRef = doc(db, 'ClientsIds', listType);
        const listDocSnap = await getDoc(listDocRef);
    
        if (!listDocSnap.exists()) {
          console.log("No such document!");
          setIsMoreAvailable(false);
          return;
        }
    
        const idsObj = listDocSnap.data().ids;
        const filteredIds = Object.entries(idsObj)
        .filter(([key, value]) => {
          // Check if onHome is 1
          const onHomeCheck = value.onHome === 1;
          
          // Safely access workingTime, ensuring it exists before accessing it
          const workingTimesExist = value.workingDays && value.workingDays.workingTime && value.workingDays.workingTime.length !== 0;
          const noFutureDates = !value.workingDays || !value.workingDays.specificDates || value.workingDays.specificDates.every(dateString => {
            const date = new Date(dateString);
            return date < new Date();
          }); 
          
          // Return true if onHome is 1 and workingTimes exists (assuming you want to include the document if workingTime exists)
          return onHomeCheck && (!noFutureDates || workingTimesExist) && !datas.some(data => data.id === key);
        })  // Exclude already displayed users
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    
        // Create a weighted array for the remaining users
        let weightedArray = [];
        for (const [key, value] of Object.entries(filteredIds)) {
          let weight = 1;
          if (value.averageRating && !isNaN(value.averageRating)) {
            weight = Math.max(value.averageRating, 1);
          }
          for (let i = 0; i < weight; i++) {
            weightedArray.push(key);
          }
        }
    
        // Shuffle the weighted array
        for (let i = weightedArray.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [weightedArray[i], weightedArray[j]] = [weightedArray[j], weightedArray[i]];
        }
    
        // Select unique keys
        let additionalKeys = new Set();
        while (additionalKeys.size < 5 && weightedArray.length > 0) {
          let randomIndex = Math.floor(Math.random() * weightedArray.length);
          additionalKeys.add(weightedArray.splice(randomIndex, 1)[0]);
        }
    
        // Fetch additional users
        const newFetchedUsers = await Promise.all([...additionalKeys].map(key => 
          getDoc(doc(db, 'Clients', key)).then(docSnap => ({ id: key, ...docSnap.data() }))
        ));
    
        setData(prevData => [...prevData, ...newFetchedUsers]);
        setIsMoreAvailable(newFetchedUsers.length >= 5);
      } catch (error) {
        console.error("Error getting more documents: ", error);
      } finally {
        setIsLoadMore(false)
    }
    
    };
    
  return (
    <div className="Root my-first-step" role="main">
      <RequestPermissionButton />
      {loading ? (
        <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", height: '100vh' }} role="status" aria-live="polite">
          <RingLoader color={'#4dffdb'} loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <div>
          <Header ref={{ listsRef, selectRef }} name={basket[0]?.name} surname={basket[0]?.surname} type={type} setType={setType} />
          <div className="CardsContainer" role="region" aria-label="Cards Container">
            <div className="RootMiniContainer" role="list">
              <div className="Search" role="search">
                  <MGlass className='MGlass' aria-hidden="true" onClick={() => setType(type.includes("search") ? type + "1" : "search" )} />
                <label htmlFor="searchInput" className="visually-hidden"></label>
                  <input id="searchInput" type="text" className="SearchInput" placeholder="Pretraži..." value={search} onChange={(e) => setSearch(e.target.value)} aria-label="Search Input" onFocus={logEvent(analytics, 'focus_search')} onKeyDown={() => setType(type.includes("search") ? type + "1" : "search" )} />
              </div>
                {loadingFeed ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh',
                    }}
                    role="status"
                    aria-live="polite"
                  >
                    <RingLoader
                      color={'#4dffdb'}
                      loading={loadingFeed}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  datas.map((data) => (
                    <Card
                      key={`${data.name}.${data.surname}`}
                      price={data.price}
                      search={search}
                      setSearch={setSearch}
                      imageURL={data.imageURL}
                      description={data.description}
                      rating={data.averageRating}
                      spec1={data.spec1}
                      spec2={data.spec2}
                      spec3={data.spec3}
                      name={data.name}
                      surname={data.surname}
                      onClick={onClickCard}
                      role="listitem"
                      aria-label={`Card for ${data.name} ${data.surname}`}
                    />
                  ))
                )}
              {isMoreAvailable && (
                <>
                    {isLoadMore ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '20px',
                        }}
                        role="status"
                        aria-live="polite"
                      >
                        <RingLoader
                          color={'#4dffdb'}
                          loading={isLoadMore}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      <button
                        onClick={loadMore}
                        className="LoadMoreButton"
                        aria-label="Load More"
                      >
                        Učitaj još +
                      </button>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Joyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        disableOverlayClose={true}
        locale={{
          back: 'Nazad',
          next: 'Sledeće',
          close: 'Close',
          last: 'Izađi',
        }}
        aria-live="polite"
      />
      <Joyride
        continuous
        run={run2}
        steps={steps2}
        callback={handleJoyrideCallback}
        disableOverlayClose={true}
        locale={{
          back: 'Nazad',
          next: 'Sledeće',
          close: 'Close',
          last: 'Izađi',
        }}
        aria-live="polite"
      />
      <Joyride
        continuous
        run={run3}
        steps={steps3}
        callback={handleJoyrideCallback2}
        disableOverlayClose={true}
        locale={{
          back: 'Nazad',
          next: 'Sledeće',
          close: 'Close',
          last: 'Izađi',
        }}
        aria-live="polite"
      />
      <VideoPopUp
        isPopUpVideoOpen={isPopUpVideoOpen}
        closeVideoPopup={closeVideoPopup}
        videoUrl={videoUrl}
        aria-live="polite"
      />
    </div>
  );
};

export default Root;